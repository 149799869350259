<owl-carousel-o [options]="customOptions" (dragging)="isDrag($event)">
  <ng-template carouselSlide>
    <a
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      [owlRouterLink]="
        !login
          ? ['/servicio/concreto']
          : ['/servicio/cotizacion-en-proceso-concreto']
      "
      [stopLink]="isDragging"
    >
      <img
        itemprop="image"
        class="dh-home-img dh-decoration-curosr-pinter"
        src="../../../assets/img/Mobile-home-top-slider01.webp"
        alt="Somos tu aliado"
      />
    </a>
  </ng-template>
  <ng-template carouselSlide>
    <a
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      [owlRouterLink]="
        !login
          ? ['/servicio/concreto']
          : ['/servicio/cotizacion-en-proceso-concreto']
      "
      [stopLink]="isDragging"
    >
      <img
        itemprop="image"
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../assets/img/Mobile-home-top-slider02.webp"
        alt="Somos tu aliado"
      />
    </a>
  </ng-template>
  <ng-template carouselSlide>
    <a
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      [owlRouterLink]="
        !login
          ? ['/servicio/concreto']
          : ['/servicio/cotizacion-en-proceso-concreto']
      "
      [stopLink]="isDragging"
    >
      <img
        itemprop="image"
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../assets/img/Mobile-home-top-slider03.webp"
        alt="cemento"
      />
    </a>
  </ng-template>
  <ng-template carouselSlide>
    <a
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      [owlRouterLink]="
        !login
          ? ['/servicio/concreto']
          : ['/servicio/cotizacion-en-proceso-concreto']
      "
      [stopLink]="isDragging"
    >
      <img
        itemprop="image"
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../assets/img/Mobile-home-top-slider04.webp"
        alt="Evita Retrabajos y Gastos Innecesarios"
      />
    </a>
  </ng-template>
  <ng-template carouselSlide>
    <a
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      [owlRouterLink]="
        !login
          ? ['/servicio/concreto']
          : ['/servicio/cotizacion-en-proceso-concreto']
      "
      [stopLink]="isDragging"
    >
      <img
        itemprop="image"
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../assets/img/Mobile-home-top-slider05.webp"
        alt="Comprar Concreto"
      />
    </a>
  </ng-template>
</owl-carousel-o>
