<div class="dh-sign-off dh-flex-column-align-center">
  <div class="dh-dialog-header-icon-div dh-flex-row-align-center-justific-end">
    <mat-icon
      class="dh-dialog-sign-off dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal(false)"
    ></mat-icon>
  </div>
  <p class="dh-text-little-titule-poppins dh-color-grey-363">
    {{ 'HOME.welcome' | translate }}
  </p>
  <p class="dh-text-semibold-poppins dh-color-grey-363">
    {{ 'HOME.text4' | translate }}
  </p>

  <div class="card-container" *ngIf="!mobile">
    <mat-card class="dh-dialog-sign-off dh-decoration-curosr-pinter">
      <a [owlRouterLink]="['/servicio/concreto']" (click)="closeModal(false)">
        <img mat-card-xl-image src="../../../assets/img/inicio-camion.webp" />
      </a>
    </mat-card>
    <mat-card class="dh-dialog-sign-off dh-decoration-curosr-pinter">
      <a [owlRouterLink]="['/servicio/concreto']" (click)="closeModal(false)">
        <img mat-card-xl-image src="../../../assets/img/inicio-bomba.webp" />
      </a>
    </mat-card>
  </div>

  <div *ngIf="mobile">
    <mat-card>
      <a (click)="closeModal(false)">
        <img mat-card-xl-image src="../../../assets/img/inicio-camion.webp" />
      </a>
    </mat-card>
    <mat-card>
      <a (click)="closeModal(false)">
        <img mat-card-xl-image src="../../../assets/img/inicio-bomba.webp" />
      </a>
    </mat-card>
  </div>

  <br />
  <div>
    <button
      (click)="closeModal(true)"
      mat-button
      class="dh-button-general dh-color-white"
    >
      {{ 'HOME.text5' | translate }}
    </button>
  </div>
</div>
