import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarAdministratorService } from 'src/app/services/navbar-administrator.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-provider-side-nav',
  templateUrl: './provider-side-nav.component.html',
  styleUrls: ['./provider-side-nav.component.scss'],
})
export class ProviderSideNavComponent implements OnInit {
  isButtonClicked = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private navbarService: NavbarAdministratorService,
  ) {}

  movileSidebarIsOpen = true;
  public login = false;
  public loading = true;
  public data: any = {
    name: '',
    surname: '',
  };

  ngOnInit(): void {
    this.getUser();
    this.authService.getStatusLogin().subscribe((status) => {
      if (status == true) {
        this.login = status;
        this.getUser();
      }

      this.login == true;
    });

    this.navbarService.isOpen$.subscribe((response) => {
      this.isButtonClicked = response;
    });
  }

  public getUser() {
    this.login = this.authService.onLogin();
    if (this.login) {
      this.userService.getUser().then((ress: any) => {
        if (ress === null) {
          this.data = {
            name: '',
            surname: '',
          };
          this.login = false;
        } else {
          this.data = ress.data.user;
        }
        this.loading = false;
      });
    }
  }

  closeMenuAfterClickOption() {
    this.navbarService.toggle();
  }
}
