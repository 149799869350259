import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-footer-administrator',
  templateUrl: './footer-administrator.component.html',
  styleUrls: ['./footer-administrator.component.scss'],
})
export class FooterAdministratorComponent implements OnInit {
  public mobile = false;

  constructor(@Inject(WINDOW) private window: Window) {
    const scrWidth = this.window.innerWidth;
    if (scrWidth <= 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  
  public session: boolean = false; 
  
  ngOnInit(): void {
   //=====================================================
    //session para mostrar la parte del footer
    let datsc =  localStorage.getItem('data');
    if ( datsc?.length == undefined ) 
    {
    this.session = false;
    }else {
      this.session = true;
    }
   //=====================================================
  }
}
