import { Component } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-layout-provider',
  templateUrl: './layout-provider.component.html',
  styleUrls: ['./layout-provider.component.scss'],
})
export class LayoutProviderComponent {
  sidebarIsOpen = false;
  constructor(private sidebarService: SidebarService) {
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
  }

  isButtonClicked = false;

  openSideNav() {
    this.isButtonClicked = !this.isButtonClicked;
  }
}
