import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { HomeComponent } from './home/home.component';
import { RecoverNewPasswordComponent } from './recover-new-password/recover-new-password.component';
import { LayoutRetailBuyerComponent } from '../shared/layouts/layout-retail-buyer/layout-retail-buyer.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { RetailBuyerLoggedGuard } from '../guard/retail-buyer-logged.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutRetailBuyerComponent,
    canActivate: [RetailBuyerLoggedGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'perfil',
        component: ProfileComponent,
      },
      {
        path: 'email-confirmado/:token',
        component: ConfirmEmailComponent,
      },
      {
        path: 'recuperar-contrasena/:token',
        component: RecoverNewPasswordComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
