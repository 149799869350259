import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CAPITAL_LETTER } from 'src/app/shared/data';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public hide = false;
  public hide2 = false;
  public hide3 = false;
  public dataForm: any;
  public errorMess: any;

  constructor(
    public dialogCole: MatDialogRef<PasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private snackBar: MatSnackBar,
    private toastService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.dataForm = new FormGroup({
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(CAPITAL_LETTER),
        Validators.minLength(8),
      ]),
      passwordConfirm: new FormControl('', [
        Validators.required,
        Validators.pattern(CAPITAL_LETTER),
        Validators.minLength(8),
      ]),
    });
  }

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }

  public async upgradeUser(): Promise<any> {
    const body = {
      newPassword: this.dataForm.controls.newPassword.value,
      oldPassword: this.dataForm.controls.oldPassword.value,
    };
    await this.userService
      .newPassword(body)
      .then((ress: any) => {
        if (ress) {
          this.openSnackBar();
          this.closeModal(true);
        }
      })
      .catch((error: any) => {
        this.dataForm.controls.oldPassword.setErrors({ incorrect: true });

        if (error.error.data.badRequest) {
          this.toastService.error(error.error.data.badRequest, 'Error', {
            disableTimeOut: false,
            timeOut: 4000,
            tapToDismiss: false,
            positionClass: 'toast-top-right',
            toastClass: 'toast-icon custom-toast-error',
          });
          this.errorMess = error.error.data.badRequest;
          return;
        }

        this.toastService.error('Algo salió mal', 'Error', {
          disableTimeOut: false,
          timeOut: 4000,
          tapToDismiss: false,
          positionClass: 'toast-top-right',
          toastClass: 'toast-icon custom-toast-error',
        });
      });
  }

  public ckeckPasswordForm(): void {
    if (
      this.dataForm.controls.newPassword.value.length >= 8 &&
      CAPITAL_LETTER.test(this.dataForm.controls.passwordConfirm.value) &&
      this.dataForm.controls.newPassword.value.length >= 8 &&
      CAPITAL_LETTER.test(this.dataForm.controls.passwordConfirm.value)
    ) {
      this.ressForm();
      if (
        this.dataForm.controls.newPassword.value !==
        this.dataForm.controls.passwordConfirm.value
      ) {
        this.errorForm();
      }
    }
  }

  public ressForm(): void {
    this.dataForm.controls.newPassword.status = 'VALID';
    this.dataForm.controls.passwordConfirm.status = 'VALID';
  }

  public errorForm(): void {
    this.dataForm.controls.passwordConfirm.setErrors({ incorrect: true });
    this.dataForm.controls.password.setErrors({ incorrect: true });
  }

  public openSnackBar() {
    this.snackBar.open('Contraseña cambiada con exito', '', {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 3000,
      panelClass: 'dh-snackbar',
    });
  }
}
