import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Translate
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Carrusel
import { CarouselModule } from 'ngx-owl-carousel-o';

// material
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './headers/user/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SignOffComponent } from './sign-off/sign-off.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { GoLoginComponent } from './go-login/go-login.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WelcomeComponent } from './welcome/welcome.component';
import { MatRadioModule } from '@angular/material/radio';

import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';

/** EDITOR MODULES */
import { TextMaskModule } from 'angular2-text-mask';
import { LoaderComponent } from './loader/loader.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient): any {
  const deploymentTimestamp = new Date().getTime();
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?${deploymentTimestamp}`,
  );
}

import { RouterModule } from '@angular/router';
// table
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BottomBannerComponent } from './bottom-banner/bottom-banner.component';
import { FlatLoaderComponent } from './flat-loader/flat-loader.component';
import { ImagesBannerComponent } from './images-banner/images-banner.component';
import { VideoBannerComponent } from './video-banner/video-banner.component';
import { MobileImagesBannerComponent } from './mobile-images-banner/mobile-images-banner.component';
import { HeaderOperatorComponent } from './headers/header-operator/header-operator.component';
import { SidebarAdministratorComponent } from './sidebar/sidebar-administrator/sidebar-administrator.component';
import { LayoutAdministratorComponent } from './layouts/layout-administrator/layout-administrator.component';
import { FooterUserComponent } from './footers/footer-user/footer-user.component';
import { HeaderAdministratorComponent } from './headers/header-administrator/header-administrator.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FooterAdministratorComponent } from './footers/footer-administrator/footer-administrator.component';
import { LayoutProviderComponent } from './layouts/layout-provider/layout-provider.component';
import { LayoutOperatorComponent } from './layouts/layout-operator/layout-operator.component';
import { LayoutRetailBuyerComponent } from './layouts/layout-retail-buyer/layout-retail-buyer.component';
import { HeaderProviderComponent } from './headers/header-provider/header-provider.component';
import { SidebarProviderComponent } from './sidebar/sidebar-provider/sidebar-provider.component';
import { SideNavComponent } from '../provider/side-nav/side-nav.component';
import { RegularSearchSectionComponent } from './regular-search-section/regular-search-section.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FooterProviderComponent } from './footers/footer-provider/footer-provider.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RegularTextSearchFieldComponent } from './regular-text-search-field/regular-text-search-field.component';
import { HeaderProspectorComponent } from './headers/header-prospector/header-prospector.component';
import { LayoutProspectorComponent } from './layouts/layout-prospector/layout-prospector.component';
import { FooterProspectorComponent } from './footers/footer-prospector/footer-prospector.component';
import { SidebarUserComponent } from './sidebar/sidebar-user/sidebar-user.component';
import { ProviderSideNavComponent } from './layouts/layout-provider/provider-side-nav/provider-side-nav.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SignOffComponent,
    MenuMobileComponent,
    GoLoginComponent,
    LoaderComponent,
    WelcomeComponent,
    BottomBannerComponent,
    HeaderAdministratorComponent,
    FlatLoaderComponent,
    ImagesBannerComponent,
    VideoBannerComponent,
    MobileImagesBannerComponent,
    HeaderOperatorComponent,
    SidebarAdministratorComponent,
    LayoutAdministratorComponent,
    FooterUserComponent,
    FooterProviderComponent,
    FooterAdministratorComponent,
    LayoutProviderComponent,
    LayoutOperatorComponent,
    LayoutRetailBuyerComponent,
    HeaderProviderComponent,
    SidebarProviderComponent,
    SideNavComponent,
    RegularSearchSectionComponent,
    RegularTextSearchFieldComponent,
    HeaderProspectorComponent,
    LayoutProspectorComponent,
    FooterProspectorComponent,
    //sidebar-user
    SidebarUserComponent,
    ProviderSideNavComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    CarouselModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatGridListModule,
    MatDividerModule,
    LottieModule.forRoot({
      player: playerFactory,
    }),
    RouterModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatDatepickerModule,
    MatSlideToggleModule,
  ],
  exports: [
    TranslateModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    CarouselModule,
    HeaderComponent,
    HeaderOperatorComponent,
    SidebarAdministratorComponent,
    LayoutAdministratorComponent,
    FooterUserComponent,
    FooterAdministratorComponent,
    HeaderAdministratorComponent,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatTooltipModule,
    MatSnackBarModule,
    LottieModule,
    MatPaginatorModule,
    MatTableModule,
    LoaderComponent,
    FlatLoaderComponent,
    MatCardModule,
    MatGridListModule,
    MatRadioModule,
    BottomBannerComponent,
    ImagesBannerComponent,
    VideoBannerComponent,
    MobileImagesBannerComponent,
    LayoutProviderComponent,
    LayoutOperatorComponent,
    LayoutRetailBuyerComponent,
    HeaderProviderComponent,
    SideNavComponent,
    RegularSearchSectionComponent,
    RegularTextSearchFieldComponent,
    ProviderSideNavComponent,
  ],
})
export class SharedModule {}
