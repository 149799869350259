<div class="dh-cp-dialog">
  <div
    class="dh-flex-row-align-inherit-justific-space-between dh-cp-dialog-header"
  >
    <p class="dh-text-big-poppins dh-color-black-323">
      {{ 'HOME.CP.enterLocation' | translate }}
    </p>
    <mat-icon
      class="dh-cp-dialog-header-icon dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal()"
    ></mat-icon>
  </div>
  <div class="dh-cp-dialog-body dh-flex-column-justific-space-between">
    <p class="dh-text-margin dh-text-botton-poppins dh-color-black-323">
      {{ 'HOME.CP.enterLocationText' | translate }}
    </p>
    <div>
      <div class="dh-mat-form-field-cp" [formGroup]="cpForm">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'HOME.CP.cp' | translate }}</mat-label>
          <input [textMask]="{ mask: pcMask }" formControlName="cp" matInput />
          <span class="dh-span" matPrefix>
            <mat-icon class="dh-cp-icon" svgIcon="ic-cp"></mat-icon>
            &nbsp; &nbsp;
          </span>
          <mat-error class="" *ngIf="cpForm.get('cp').hasError('required')">
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error class="" *ngIf="cpForm.get('cp').hasError('minlength')">
            {{ 'AUTH.errorCp' | translate }}
          </mat-error>
          <mat-error class="" *ngIf="cpForm.get('cp').hasError('incorrect')">
            {{ cpFormError }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <button
          (click)="emitCp()"
          [disabled]="cpForm.status === 'INVALID'"
          mat-button
          class="dh-button-general dh-text-regular-big-poppins dh-color-white"
        >
          {{ 'HOME.CP.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
