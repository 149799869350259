import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-sidebar-user',
  templateUrl: './sidebar-user.component.html',
  styleUrls: ['./sidebar-user.component.scss'],
})
export class SidebarUserComponent implements OnInit {
  isButtonClicked = false;
  sidebarIsOpen = false;
  userModuleOptions: any = [];

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
  ) {}

  openSideNav() {
    this.isButtonClicked = !this.isButtonClicked;
  }

  ngOnInit(): void {
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });

    this.userModuleOptions = this.authService.getUserModuleOptions();
    // retornamos el side bar
  }

  // Modulos []
  // Permisos []

  public handleSideBarMenu() {
    this.sidebarService.toggle();
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
  }
}
