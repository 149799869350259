import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErroresService {

  constructor() { }

  public getErrores(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `La Servicio No Identificado: ${error.message}`;
      }
      case 401: {
        return `La Servicio No Proveeido | Encontrado: ${error.message}`;
      }
      case 404: {
        return `La URL no esta disponible: ${error.message}`;
      }
      case 403: {
        return `No tienes Acceso: ${error.message}`;
      }
      case 500: {
        return `Problemas con el servicio: ${error.message}`;
      }
      default: {
        return `Tenemos un problema que no identifico: ${error.message}`;
      }
    }
  }
}
