<div class="dh-data-dialog">
  <div
    class="dh-flex-row-align-inherit-justific-space-between dh-data-dialog-header"
  >
    <p class="dh-text-big-poppins dh-color-black-323">
      {{ 'PROFILE.editProfile' | translate }}
    </p>
    <mat-icon
      class="dh-data-dialog-header-icon dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal(false)"
    ></mat-icon>
  </div>
  <div class="dh-data-dialog-body dh-flex-column-justific-space-between">
    <div [formGroup]="dataForm">
      <div class="dh-mat-form-field-general">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROFILE.names' | translate }}</mat-label>
          <input
            formControlName="name"
            matInput
            [placeholder]="'PROFILE.names' | translate"
          />
          <mat-error *ngIf="dataForm.get('name').hasError('required')">
            {{ 'PROFILE.errorRequiredField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dh-mat-form-field-general">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROFILE.surname' | translate }}</mat-label>
          <input
            formControlName="surname"
            matInput
            [placeholder]="'PROFILE.surname' | translate"
          />
          <mat-error *ngIf="dataForm.get('surname').hasError('required')">
            {{ 'PROFILE.errorRequiredField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dh-mat-form-field-general">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROFILE.surnameLast' | translate }}</mat-label>
          <input
            formControlName="secondSurname"
            matInput
            [placeholder]="'PROFILE.surnameLast' | translate"
          />
        </mat-form-field>
      </div>
      <div class="dh-mat-form-field-general">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROFILE.phone' | translate }}</mat-label>
          <input
            [textMask]="{ mask: phoneMask }"
            formControlName="phone"
            matInput
            [placeholder]="'PROFILE.phone' | translate"
          />
          <mat-error *ngIf="dataForm.get('phone').hasError('required')">
            {{ 'PROFILE.errorRequiredField' | translate }}
          </mat-error>
          <mat-error
            class=""
            *ngIf="dataForm.get('phone').hasError('minlength')"
          >
            {{ 'PROFILE.errorMinLengthPhone' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="dh-button">
        <button
          [disabled]="dataForm.status === 'INVALID'"
          (click)="upgradeUser()"
          mat-button
          class="dh-button-general dh-text-regular-big-poppins dh-color-white"
        >
          <b>{{ 'PROFILE.save' | translate }}</b>
        </button>
      </div>
    </div>
  </div>
</div>
