import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionsGuard } from './guard/permissions.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'acceso',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('src/app/profile/profile.module').then((m) => m.ProfileModule),
  },
  // {
  //   path: 'maps',
  //   loadChildren: () =>
  //     import('src/app/maps/maps.module').then((m) => m.MapsModule),
  // },
  {
    path: 'servicio',
    loadChildren: () =>
      import('src/app/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'proveedor',
    loadChildren: () =>
      import('src/app/provider/provider.module').then((m) => m.ProviderModule),
  },
  {
    path: 'info',
    loadChildren: () =>
      import('src/app/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'administrador',
    loadChildren: () =>
      import('src/app/administrator/administrator.module').then(
        (m) => m.AdministratorModule,
      ),
  },
  {
    path: 'operador',
    loadChildren: () =>
      import('src/app/operator/operator.module').then((m) => m.OperatorModule),
  },
  {
    path: 'prospectador',
    loadChildren: () =>
      import('src/app/prospector/prospector.module').then(
        (m) => m.ProspectorModule,
      ),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
