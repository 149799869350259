

<div *ngIf=" session == false ">
  <div class="dh-footer" *ngIf="!mobile">
    <div
      class="dh-footer-bottom dh-color-white"
      [ngClass]="{
        'dh-flex-column-reverse-align-center-justific-flex-end dh-footer-height': mobile,
        'dh-flex-row-align-center-justific-space-between dh-flex': !mobile
      }"
    >
      <p class="dh-text-regular-small-poppins">
        <span class="dh-footer-rights">
          {{ 'FOOTER.dochmart2022' | translate }}
        </span>
      </p>
      <div
        class="dh-footer-bottom-terms dh-footer-terms"
        [ngClass]="{
          'dh-flex-column-align-center-justific-space-around': mobile,
          'dh-text-regular-poppins': !mobile
        }"
      >
        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Términos y condiciones"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.terms' | translate }}
        </a>
        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Aviso de privacidad"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.privacy' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf=" session == false ">
  <div class="dh-footer" *ngIf="mobile">
    <div
      class="dh-footer-bottom dh-color-white"
      [ngClass]="{
        'dh-flex-column-reverse-align-center-justific-flex-end': mobile,
        'dh-flex-row-align-center-justific-space-between': !mobile
      }"
    >
      <p class="dh-text-regular-small-poppins">
        {{ 'FOOTER.dochmart2022' | translate }}
      </p>
      <div
        class="dh-footer-bottom-terms"
        [ngClass]="{
          'dh-flex-column-align-center-justific-space-around': mobile,
          'dh-flex-row-justific-space-between dh-text-regular-poppins': !mobile
        }"
      >

        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Términos y condiciones"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.terms' | translate }}
        </a>

        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Aviso de privacidad"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.privacy' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
