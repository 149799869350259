import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RetailBuyerLoggedGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let access = false;
    if (this._authService.hasTypeUserLogged('provider')) {
      // this._router.navigate(['./proveedor/reservas']);
      this._router.navigate(['./proveedor/reservas']);
      access = false;
    } else if (
      // this._authService.hasTypeUserLogged('operationalMaster') ||
      // this._authService.hasTypeUserLogged('plantManager') ||
      this._authService.hasTypeUserLogged('weigher') ||
      this._authService.hasTypeUserLogged('administrativeAssistant') ||
      this._authService.hasTypeUserLogged('administrative')
      // this._authService.hasTypeUserLogged('operatorUR') ||
      // this._authService.hasTypeUserLogged('provider')
    ) {
      this._router.navigate(['./proveedor/reservas']);
      access = false;
    } else if (this._authService.hasTypeUserLogged('administrator')) {
      this._router.navigate(['./administrador/dashboard']);
      access = false;
    } else if (this._authService.hasTypeUserLogged('operatorUR')) {
      this._router.navigate(['./proveedor/reservas']);
      access = false;
    } else if (this._authService.hasTypeUserLogged('retailBuyer')) {
      access = true;
    } else {
      access = true;
    }
    document.documentElement.scrollTop = 0;
    return access;
  }
}
