import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { isPlatformServer } from '@angular/common';
import { href } from 'src/environments/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  private isServer: boolean;
  public options: AnimationOptions;
  public hrefAux = href;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isServer = isPlatformServer(this.platformId);
    this.options = {
      path: this.isServer
        ? this.hrefAux + 'assets/loader/lf30_editor_elv9lsvd.json'
        : 'assets/loader/lf30_editor_elv9lsvd.json',
    };
  }
}
