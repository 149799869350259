<div *ngIf="!mobile" class="dh-home-carrusel">
  <owl-carousel-o [options]="customOptionsTop" (dragging)="isDrag($event)">
    <ng-template
      carouselSlide
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
    >
      <img
        itemprop="image"
        (click)="
          !login
            ? goRouter('/servicio/concreto')
            : goRouter('/servicio/cotizacion-en-proceso-concreto')
        "
        class="dh-home-img dh-decoration-curosr-pinter"
        src="../../../assets/img/Home-web_banner_marzo-2024.webp"
        alt="Somos tu aliado"
      />
    </ng-template>
    <ng-template
      carouselSlide
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
    >
      <img
        itemprop="image"
        (click)="
          !login
            ? goRouter('/servicio/concreto')
            : goRouter('/servicio/cotizacion-en-proceso-concreto')
        "
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../../../assets/img/Home-web_banner_01.webp"
        alt="Somos tu aliado"
      />
    </ng-template>
    <ng-template
      carouselSlide
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
    >
      <img
        itemprop="image"
        (click)="
          !login
            ? goRouter('/servicio/concreto')
            : goRouter('/servicio/cotizacion-en-proceso-concreto')
        "
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../../../assets/img/Home-web_banner_02.webp"
        alt="Transacciones Seguras"
      />
    </ng-template>
    <ng-template
      carouselSlide
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
    >
      <img
        itemprop="image"
        (click)="
          !login
            ? goRouter('/servicio/concreto')
            : goRouter('/servicio/cotizacion-en-proceso-concreto')
        "
        class="dh-home-img dh-decoration-curosr-pinter"
        src=" ../../../../../assets/img/Home-web_banner_03.webp"
        alt="Evita Retrabajos y Gastos Innecesarios"
      />
    </ng-template>
  </owl-carousel-o>
</div>

<div *ngIf="mobile" class="dh-home-carrusel dh-flex-column-justify-center">
  <owl-carousel-o [options]="customOptionsTop" (dragging)="isDrag($event)">
    <ng-template carouselSlide>
      <a
        itemscope
        itemtype="https://schema.org/HomeAndConstructionBusiness"
        [owlRouterLink]="
          !login
            ? ['/servicio/concreto']
            : ['/servicio/cotizacion-en-proceso-concreto']
        "
        [stopLink]="isDragging"
      >
        <img
          itemprop="image"
          class="dh-home-img dh-decoration-curosr-pinter"
          src="../../../assets/img/Home-mobile_banner_marzo-2024.webp"
          alt="Somos tu aliado"
        />
      </a>
    </ng-template>
    <ng-template carouselSlide>
      <a
        itemscope
        itemtype="https://schema.org/HomeAndConstructionBusiness"
        [owlRouterLink]="
          !login
            ? ['/servicio/concreto']
            : ['/servicio/cotizacion-en-proceso-concreto']
        "
        [stopLink]="isDragging"
      >
        <img
          itemprop="image"
          class="dh-home-img dh-decoration-curosr-pinter"
          src=" ../../../../../assets/img/Banner_01_mobile.webp"
          alt="Somos tu aliado"
        />
      </a>
    </ng-template>
    <ng-template carouselSlide>
      <a
        itemscope
        itemtype="https://schema.org/HomeAndConstructionBusiness"
        [owlRouterLink]="
          !login
            ? ['/servicio/concreto']
            : ['/servicio/cotizacion-en-proceso-concreto']
        "
        [stopLink]="isDragging"
      >
        <img
          itemprop="image"
          class="dh-home-img dh-decoration-curosr-pinter"
          src="../../../assets/img/Banner_02_mobile.webp"
          alt="cemento"
        />
      </a>
    </ng-template>
    <ng-template carouselSlide>
      <a
        itemscope
        itemtype="https://schema.org/HomeAndConstructionBusiness"
        [owlRouterLink]="
          !login
            ? ['/servicio/concreto']
            : ['/servicio/cotizacion-en-proceso-concreto']
        "
        [stopLink]="isDragging"
      >
        <img
          itemprop="image"
          class="dh-home-img dh-decoration-curosr-pinter"
          src=" ../../../../../assets/img/Banner_01_mobile.webp"
          alt="Evita Retrabajos y Gastos Innecesarios"
        />
      </a>
    </ng-template>
  </owl-carousel-o>
</div>
