import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { href } from 'src/environments/environment';

@Component({
  selector: 'app-flat-loader',
  templateUrl: './flat-loader.component.html',
  styleUrls: ['./flat-loader.component.scss'],
})
export class FlatLoaderComponent {
  private isServer: boolean;
  public options: AnimationOptions;
  public hrefAux = href;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private dom: Document,
  ) {
    //Cambiar dominio en productivo
    this.isServer = isPlatformServer(platformId);
    this.options = {
      path: this.isServer
        ? this.hrefAux + 'assets/loader/animacion.json'
        : 'assets/loader/animacion.json',
    };
  }
}
