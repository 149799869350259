import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from 'src/app/services/localStorage.service';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent {
  public panelOpenState = false;
  constructor(
    public dialogCole: MatDialogRef<MenuMobileComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navigator: Router,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
  ) {}

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }

  public goRoute(url: string): void {
    if (url === 'acceso/registro') {
      this.localStorage.removeItem('data');
    }
    this.closeModal(false);
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
