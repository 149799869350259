import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusinessComponent } from '../business/business.component';
import { DataComponent } from '../data/data.component';
import { PasswordComponent } from '../password/password.component';
import { UserService } from 'src/app/services/user.service';
import { BambuService } from 'src/app/services/bambu.service';
import { WINDOW } from 'src/app/services/window.service';
import { LOCAL_STORAGE } from 'src/app/services/localStorage.service';
import { Title } from '@angular/platform-browser';
import { SignOffComponent } from '../../shared/sign-off/sign-off.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public mobile = false;
  public user: any;
  public view = false;
  public loader = true;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    if (scrWidth < 1360 && scrWidth > 1112) {
      this.view = false;
    } else {
      this.view = true;
    }
  }

  constructor(
    private dialog: MatDialog,
    private navigator: Router,
    private userService: UserService,
    private bambuService: BambuService,
    private authService: AuthService,
    private toastService: ToastrService,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private titleService: Title,
  ) {
    const scrWidth = this.window.innerWidth;
    if (scrWidth <= 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    if (scrWidth < 1360 && scrWidth > 1112) {
      this.view = false;
    } else {
      this.view = true;
    }
    this.titleService.setTitle('Dochmart - Perfil');
  }

  ngOnInit(): void {
    this.bambuService.setCanonicalURL();
    this.getUser();
  }

  public async getUser(): Promise<any> {
    await this.userService
      .getUser()
      .then((ress: any) => {
        console.log(ress)
        if (ress === null) {
          this.localStorage.removeItem('data');
          this.navigator.navigate(['']);
          document.documentElement.scrollTop = 0;
        }
        this.user = ress.data.user;
        if (this.user.company === null) {
          this.user.company = { name_company: '' };
        }
      })
      .catch((error) => {
        if (error.error.data.internalServerError) {
          this.toastService.error(
            error.error.data.internalServerError,
            'Error',
            {
              disableTimeOut: false,
              timeOut: 4000,
              tapToDismiss: false,
              positionClass: 'toast-top-right',
              toastClass: 'toast-icon custom-toast-error',
            },
          );
          return;
        }
        this.toastService.error('Algo salió mal', 'Error', {
          disableTimeOut: false,
          timeOut: 4000,
          tapToDismiss: false,
          positionClass: 'toast-top-right',
          toastClass: 'toast-icon custom-toast-error',
        });
      });
    this.loader = false;
  }

  public goEditData() {
    const dialogRef = this.dialog.open(DataComponent, {
      width: '577px',
      disableClose: true,
      autoFocus: false,
      data: { user: this.user },
    });
    dialogRef.afterClosed().subscribe(async () => {
      this.bambuService.emitUpdate();
      this.getUser();
    });
  }

  public goEditBusiness() {
    const dialogRef = this.dialog.open(BusinessComponent, {
      width: '577px',
      disableClose: true,
      autoFocus: false,
      data: { user: this.user },
    });
    dialogRef.afterClosed().subscribe(async () => {
      this.getUser();
    });
  }

  public upgradePassword() {
    const dialogRef = this.dialog.open(PasswordComponent, {
      width: '577px',
      disableClose: true,
      autoFocus: false,
      data: {},
    });
    dialogRef.afterClosed().subscribe(async () => {
      this.getUser();
    });
  }

  public logout() {
    if (this.mobile) {
      const dialogRef = this.dialog.open(SignOffComponent, {
        minWidth: '90%',
        height: '100%',
        maxHeight: '300px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/registro');
          this.authService.checkLogin();
        }
      });
    }
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
