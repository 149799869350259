
export const environment = {
  production: false,
  googleMapsApiKey: 'AIzaSyD6dJAGXlvt2U11k-4BrbamQOi47_WtkK4',
  // serverSocket: 'http://localhost:5000',

  //DEV
  // rootUrl: 'https://app-dochmart-api-stg-9cbe8b5afdaa.herokuapp.com',
  //rootUrl: 'http://localhost:3000',

  //PROD
  rootUrl: 'https://app-dochmart-api-prod.herokuapp.com',
  // rootUrl: 'http://localhost:3000',



  // aws_credentials: {
  //   AWS_ACCESS_KEY_ID: 'AKIAVZH4SBSY2HFGHGEF',
  //   AWS_SECRET_ACCESS_KEY: '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',
  //   REGION: 'us-east-1',
  //   BUCKET_NAME: 'bucketeer-9d3cae0c-9e29-450d-8eb4-43231efdeaf4',
  //   PUBLIC_URL:
  //     'https://bucketeer-9d3cae0c-9e29-450d-8eb4-43231efdeaf4.s3.amazonaws.com/public/',
  // },
  aws: {
    accessKeyId: 'AKIA3FLD4ADNUTGQO26Z',
    secretAccessKey: '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',
    region: 'us-east-2',
    BUCKET_NAME: 'pdf-dochmart',
  },
};
export const href = 'https://app-dochmart-prod2-211be4e349ae.herokuapp.com/';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
