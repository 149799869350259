import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-regular-text-search-field',
  templateUrl: './regular-text-search-field.component.html',
  styleUrls: ['./regular-text-search-field.component.scss'],
})
export class RegularTextSearchFieldComponent {
  @Input() placeholderText = '';
  @Input() calendarFilterOnlyToDesktop = false;
  @Input() filterable = true;
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter<any>();
  userCanCreateUser = false;
  constructor(private authService: AuthService) {
    this.userCanCreateUser = this.authService.userCanCreateUser();
  }

  public isCalendarIconColorActive = false;

  public toggleCalendarStyleState() {
    this.isCalendarIconColorActive = !this.isCalendarIconColorActive;
  }

  public searchText(event: any) {
    const searchText = event.target.value.toString().toLowerCase().trim();
    this.searchTextChange.emit(searchText);
  }
}
