import { NavigationEnd, Router } from '@angular/router';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CpComponent } from 'src/app/home/cp/cp.component';
import { MatDialog } from '@angular/material/dialog';
import { SignOffComponent } from '../../sign-off/sign-off.component';
import { MenuMobileComponent } from '../../menu-mobile/menu-mobile.component';
import { AuthService } from 'src/app/services/auth.service';
import { SubSink } from 'subsink';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { WINDOW } from 'src/app/services/window.service';
import { LOCAL_STORAGE } from '../../../services/localStorage.service';

@Component({
  selector: 'app-header-operator',
  templateUrl: './header-operator.component.html',
  styleUrls: ['./header-operator.component.scss'],
})
export class HeaderOperatorComponent implements OnInit {
  public subscriptions = new SubSink();
  public mobile = false;
  public login = false;
  public data: any = {
    nombre: '',
    apellido: '',
  };
  public cp = '';
  public administratorRoute = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  constructor(
    private dialog: MatDialog,
    private navigator: Router,
    private authService: AuthService,
    private userService: UserService,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(PLATFORM_ID) private plataformId: any,
  ) {
    if (isPlatformBrowser(plataformId)) {
      const scrWidth = this.window.innerWidth;
      if (scrWidth <= 1111) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
      const dataAux: any = localStorage.getItem('cp');
      if (dataAux) {
        const cpAux = JSON.parse(dataAux);
        this.cp = cpAux.cp;
      }
    }
  }

  ngOnInit(): void {
    this.getUser();
    this.authService.getStatusLogin().subscribe((status) => {
      if (status == true) {
        this.login = status;
        this.getUser();
      }
    });
    this.navigator.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (url.startsWith('/administrador')) {
          this.administratorRoute = true;
        } else if (url.startsWith('/servicio/proceso-de-pago')) {
          this.administratorRoute = true;
        } else {
          this.administratorRoute = false;
        }
      }
    });
    const currentPath = this.window.location?.pathname;
    if (currentPath.startsWith('/administrador')) {
      this.administratorRoute = true;
    } else if (currentPath.startsWith('/servicio/proceso-de-pago')) {
      this.administratorRoute = true;
    }
  }

  public getUser() {
    this.login = this.authService.onLogin();
    if (this.login) {
      this.userService.getUser().then((ress: any) => {
        if (ress === null) {
          this.data = {
            nombre: '',
            apellido: '',
          };
          this.login = false;
        } else {
          this.data = ress.data.user;
        }
      });
    }
  }

  public goToDetails() {
    if (!this.mobile) {
      const dialogRef = this.dialog.open(CpComponent, {
        minWidth: '580px',
        minHeight: '360px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        this.cp = result;
      });
    } else {
      const dialogRef = this.dialog.open(CpComponent, {
        width: '328px',
        height: '390px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        this.cp = result;
      });
    }
  }

  public goToSignOff() {
    if (!this.mobile) {
      const dialogRef = this.dialog.open(SignOffComponent, {
        minWidth: '49px',
        minHeight: '385px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    } else {
      const dialogRef = this.dialog.open(SignOffComponent, {
        width: '238px',
        height: '351px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    }
  }

  public goMenuMovile() {
    this.dialog.open(MenuMobileComponent, {
      panelClass: 'dh-menu-dialog',
      width: '423px',
      minHeight: '360px',
      disableClose: true,
      autoFocus: false,
      data: {
        login: this.login,
        name: this.login ? this.data.name + ' ' + this.data.surname : '',
      },
    });
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
