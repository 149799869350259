<div class="dh-confirm-new-password">
  <div
    class="dh-confirm-new-password-body dh-flex-row-align-center-justific-center"
  >
    <div
      [formGroup]="newPassword"
      *ngIf="!confirm"
      class="dh-confirm-new-password-box"
    >
      <p class="dh-text-regular-titule-poppins dh-color-black-323">
        {{ 'HOME.PASSWORD.recover' | translate }}
      </p>
      <p
        class="dh-confirm-new-password-box-text dh-text-regular-big-poppins dh-color-black-323"
      >
        {{ 'HOME.PASSWORD.newPassword' | translate }}
      </p>

      <div class="dh-mat-form-field-general dh-mat-form-field-general-margin">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AUTH.password' | translate }}</mat-label>
          <input
            (input)="ckeckPasswordF()"
            formControlName="password"
            (keyup)="ckeckPasswordForm()"
            matInput
            [placeholder]="'AUTH.password' | translate"
            [type]="hide ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            class="dh-button-password"
          >
            <img
              *ngIf="!hide"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility.svg"
              alt="Cotizar concreto premezclado"
            />
            <img
              *ngIf="hide"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility_off.svg"
              alt="Venta de concreto premezclado"
            />
          </button>
          <mat-error *ngIf="newPassword.get('password').hasError('required')">
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="newPassword.get('password').hasError('minlength')">
            {{ 'AUTH.errorMinLength' | translate }}
          </mat-error>
          <mat-error *ngIf="newPassword.get('password').hasError('incorrect')">
            {{ 'HOME.PASSWORD.confirmPassword' | translate }}
          </mat-error>
          <img
            *ngIf="passwordsMatch && newPassword.controls.password.valid"
            class="dh-green-tick"
            src="../../../assets/icons/green-tick.svg"
            alt="Cotizar concreto premezclado"
          />
          <img
            *ngIf="
              !passwordsMatch &&
              newPassword.controls.passwordConfirm.dirty &&
              newPassword.controls.password.dirty
            "
            class="dh-green-tick"
            src="../../../assets/icons/incorrect-red.svg"
            alt="Cotizar concreto premezclado"
          />
        </mat-form-field>
      </div>

      <div class="dh-mat-form-field-general dh-mat-form-field-general-margin">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AUTH.setPassword' | translate }}</mat-label>
          <input
            formControlName="passwordConfirm"
            (input)="ckeckPasswordF()"
            (keyup)="ckeckPasswordForm()"
            matInput
            [placeholder]="'AUTH.setPassword' | translate"
            [type]="hide2 ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide2 = !hide2"
            [attr.aria-label]="'Hide2 password'"
            [attr.aria-pressed]="hide2"
            class="dh-button-password"
          >
            <img
              *ngIf="!hide2"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility.svg"
              alt="Cotizar concreto premezclado"
            />
            <img
              *ngIf="hide2"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility_off.svg"
              alt="Venta de concreto premezclado"
            />
          </button>
          <mat-error
            *ngIf="newPassword.get('passwordConfirm').hasError('required')"
          >
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error
            *ngIf="newPassword.get('passwordConfirm').hasError('minlength')"
          >
            {{ 'AUTH.errorMinLength' | translate }}
          </mat-error>
          <mat-error
            *ngIf="newPassword.get('passwordConfirm').hasError('incorrect')"
          >
            {{ 'HOME.PASSWORD.confirmPassword' | translate }}
          </mat-error>
          <img
            *ngIf="passwordsMatch && newPassword.controls.passwordConfirm.valid"
            class="dh-green-tick"
            src="../../../assets/icons/green-tick.svg"
            alt="Cotizar concreto premezclado"
          />
          <img
            *ngIf="
              !passwordsMatch &&
              newPassword.controls.passwordConfirm.dirty &&
              newPassword.controls.password.dirty
            "
            class="dh-green-tick"
            src="../../../assets/icons/incorrect-red.svg"
            alt="Cotizar concreto premezclado"
          />
        </mat-form-field>
      </div>
      <p
        *ngIf="
          !passwordsMatch &&
          newPassword.controls.passwordConfirm.dirty &&
          newPassword.controls.password.dirty
        "
        class="dh-no-match"
      >
        {{ 'HOME.EMAIL_SEND.noMatch' | translate }}
      </p>
      <p *ngIf="passwordsMatch" class="dh-match">
        {{ 'HOME.EMAIL_SEND.match' | translate }}
      </p>
      <button
        [disabled]="newPassword.status !== 'VALID'"
        mat-button
        class="dh-button-general dh-color-white dh-text-botton-poppins dh-new-password dh-button-reset-password"
        (click)="newPass()"
      >
        {{ 'HOME.PASSWORD.recover' | translate }}
      </button>
    </div>

    <div
      class="dh-confirm-new-password-check dh-flex-column-align-center"
      *ngIf="confirm"
    >
      <div>
        <mat-icon
          class="dh-confirm-password-logo"
          svgIcon="ic-check"
        ></mat-icon>
      </div>
      <p class="dh-text-changed dh-text-big-poppins dh-color-black-323">
        {{ 'HOME.PASSWORD.changed' | translate }}
      </p>
      <p class="dh-text-retry dh-text-regular-big-poppins dh-color-grey-649">
        {{ 'HOME.PASSWORD.retry' | translate }}
      </p>
      <button
        class="dh-button-go-login"
        (click)="goRouter('/acceso/iniciar-sesion')"
        mat-flat-button
        color="primary"
      >
        {{ 'HOME.login' | translate }}
      </button>
    </div>
  </div>
  <div class="dh-footer-reset-password">
    <div class="dh-max-bottom-footer">
      <div>
        <p class="dh-text-regular-small-poppins">
          {{ 'FOOTER.dochmart2022' | translate }}
        </p>
      </div>
      <div class="dh-terms">
        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Términos y condiciones"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.terms' | translate }}
        </a>
        &nbsp;
        <span class="dh-separator">|</span>
        &nbsp;
        <a
          href="https://www.dochmart.com/info/terminos-y-condiciones/Aviso de privacidad"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white"
        >
          {{ 'FOOTER.privacy' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="loader"></app-loader>
