import { Component, Inject, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-images-banner',
  templateUrl: './images-banner.component.html',
  styleUrls: ['./images-banner.component.scss'],
})
export class ImagesBannerComponent implements OnInit {
  @Input() mobile = false;
  constructor(
    private authService: AuthService,
    private navigator: Router,
    @Inject(WINDOW) private window: Window,
  ) {}

  public login = false;
  public isDragging = false;

  isDrag(event: any) {
    this.isDragging = event.dragging;
  }

  customOptionsTop: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    nav: true,
    autoplay: true,
    navText: [
      '<div class="custom-prev-arrow"></div>',
      '<div class="custom-next-arrow"></div>',
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  ngOnInit(): void {
    this.login = this.authService.onLogin();
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
