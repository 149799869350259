<div class="dh-data-dialog">
  <div
    class="dh-flex-row-align-inherit-justific-space-between dh-data-dialog-header"
  >
    <p class="dh-text-big-poppins dh-color-black-323">
      {{ 'PROFILE.companyName' | translate }}
    </p>
    <mat-icon
      class="dh-data-dialog-header-icon dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal(false)"
    ></mat-icon>
  </div>
  <div class="dh-data-dialog-body dh-flex-column-justific-space-between">
    <div [formGroup]="dataForm">
      <div class="dh-mat-form-field-general">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROFILE.business' | translate }}</mat-label>
          <input
            [maxlength]="50"
            formControlName="business"
            matInput
            [placeholder]="'PROFILE.business' | translate"
          />
        </mat-form-field>
      </div>

      <div class="dh-button">
        <button
          [disabled]="dataForm.status === 'INVALID'"
          (click)="upgradeUser()"
          mat-button
          class="dh-button-general dh-text-regular-big-poppins dh-color-white dh-save-bussines-name dh-bussines-name"
        >
          <b>{{ 'PROFILE.save' | translate }}</b>
        </button>
      </div>
    </div>
  </div>
</div>
