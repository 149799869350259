import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const autoCorrectedDatePipe =
  createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

export const PARTNER_PHONE_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 10,
});

export const PARTNER_ID_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 6,
});

export const CURRENCY_MASK = createNumberMask({
  prefix: '$ ',
  suffix: ' MXN',
  includeThousandsSeparator: true,
  allowDecimal: true,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 6,
});

export const VOLUME_MASK = createNumberMask({
  prefix: '',
  suffix: ' ml',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 4,
});

export const PRIORITY_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 4,
});

export const PERCENTAGE_MASK = createNumberMask({
  prefix: '',
  suffix: ' %',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 3,
});

export const PHONE_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 10,
});

export const CP_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 5,
});

export const YEAR = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 4,
});

export const MaxLimit = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 5,
});

export const NUMBER_FEATURE = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalLimit: 1,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 2,
});

export const SKU = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 40,
});

export const ZIP = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 5,
});

export const CODE_SMS_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  integerLimit: 4,
});
