<div class="dh-confirm-email">
  <div class="dh-confirm-email-body dh-flex-row-align-center-justific-center">
    <div
      *ngIf="verify"
      class="dh-confirm-email-box dh-flex-column-align-center"
    >
      <mat-icon class="dh-confirm-email-logo" svgIcon="ic-check"></mat-icon>
      <p
        class="dh-confirm-email-titule dh-text-little-titule-poppins dh-color-grey-649"
      >
        {{ 'HOME.EMAIL.confirmedEmail' | translate }}
      </p>
      <p
        class="dh-confirm-email-text dh-text-regular-big-poppins dh-color-grey-649"
      >
        {{ 'HOME.EMAIL.confirmedEmailText' | translate }}
      </p>
      <div class="dh-confirm-line"></div>
      <button
        (click)="goLogin()"
        mat-button
        class="dh-button-general dh-color-white"
      >
        {{ 'HOME.EMAIL.login' | translate }}
      </button>
    </div>
  </div>
</div>
