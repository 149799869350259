<div class="container">
  <nav [ngClass]="{ transformed: isButtonClicked }">
    <div class="dh-profil-container">
      <img src="../../../../assets/icons/user-icon.svg" alt="icono de usuario" width="33px" height="33px" />
      <div [ngClass]="{ 'dh-text-profil': login, 'dh-text-profil-off': !login }"
        class="dh-text-profil dh-flex-column-justify-start">
        <p *ngIf="login" class="dh-text-regular-poppins dh-color-grey-649">
          {{ 'HOME.account' | translate }}

          <img class="arrow-right-regular" src="./assets/icons/arrow-right-regular.svg" alt="arrow" />
        </p>
        <p [ngClass]="{ 'dh-decoration-curosr-pinter': !login }" class="dh-profil-text dh-color-blue" type="button"
          routerLink="/administrador/perfil">
          {{ login ? data.name + ' ' + data.surname : '' }}
        </p>
      </div>
    </div>

    <mat-nav-list>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/dashboard" routerLinkActive="active-link">
        <div class="dh-icon-dashboard">
          <img width="35px" height="29.25px" src="./assets/icons/side-nav/administrator/dashboard.svg"
            alt="dashboard de administrador" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.dashboard' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/seccion/solicitudes/"
        routerLinkActive="active-link">
        <div class="dh-icon-writting dh-icon-mobile">
          <img width="35px" height="35px" src="./assets/icons/side-nav/administrator/writting.svg" alt="solicitudes" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.requests' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/proveedores" routerLinkActive="active-link">
        <div class="dh-icon-store dh-icon-mobile">
          <img width="35px" height="29px" src="./assets/icons/side-nav/administrator/store.svg"
            alt="detalles de pedidos de proveedor" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.providers' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>

      <a mat-list-item class="sidenav-item" routerLink="/proveedor/lstplant" routerLinkActive="active-link">
        <div class="dh-icon-people-group dh-icon-mobile">
          <img width="35px" height="32px" src="./assets/icons/side-nav/administrator/people-group.svg" alt="usuarios" />
        </div>
        <span class="nav-text">
          plantas
        </span>
        <span class="selection-bar"></span>
      </a>


      <a mat-list-item class="sidenav-item" routerLink="/proveedor/plantas/1" routerLinkActive="active-link">
        <div class="dh-icon-people-group dh-icon-mobile">
          <img width="35px" height="32px" src="./assets/icons/side-nav/administrator/people-group.svg" alt="usuarios" />
        </div>
        <span class="nav-text">

          Nueva Planta

        </span>
        <span class="selection-bar"></span>
      </a>


      <a mat-list-item class="sidenav-item" routerLink="/administrador/seccion/prospectos"
        routerLinkActive="active-link">
        <div class="dh-icon-person-in-document dh-icon-mobile">
          <img width="35px" height="39px" src="./assets/icons/side-nav/administrator/person-in-document.svg"
            alt="Prospectos" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.prospects' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/clientes" routerLinkActive="active-link">
        <div class="dh-icon-people dh-icon-mobile">
          <img width="35px" height="32.5px" src="./assets/icons/side-nav/administrator/people.svg" alt="Clientes" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.clients' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>

      <!-- <a mat-list-item class="sidenav-item">
        routerLink="/administrador/seccion/reportes" routerLinkActive="active-link"
        <div class="dh-icon-statistics dh-icon-mobile">
          <img width="35px" height="29.25px" src="./assets/icons/side-nav/administrator/statistics.svg"
            alt="clientes" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.reports' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a> -->

      <a mat-list-item class="sidenav-item" routerLink="/administrador/usuarios" routerLinkActive="active-link">
        <div class="dh-icon-people-group dh-icon-mobile">
          <img width="35px" height="32px" src="./assets/icons/side-nav/administrator/people-group.svg" alt="usuarios" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.users' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
    </mat-nav-list>
  </nav>
  <div (click)="closeMenuAfterClickOption()" class="overlay" [ngClass]="{ active: isButtonClicked }"></div>
</div>