<div class="dh-ajust-margin-top">
  <div *ngIf="mobileCarrucel" class="dh-home-cards-min">
    <owl-carousel-o
      [options]="customOptionsBottom"
      class="dh-home-cards-min-box dh-flex-row-justific-space-between"
    >
      <ng-template carouselSlide>
        <a
          id="link"
          (click)="
            goRouter(
              !login
                ? '/servicio/concreto'
                : '/servicio/cotizacion-en-proceso-concreto'
            )
          "
        >
          <div
            class="dh-home-cards-contain"
            itemscope
            itemtype="https://schema.org/HomeAndConstructionBusiness"
          >
            <p
              class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
              itemprop="name"
            >
              {{ 'HOME.quickDelivery' | translate }}
            </p>
            <div
              class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
              itemprop="description"
            >
              {{ 'HOME.text1' | translate }}
            </div>
            <div
              loading="lazy"
              class="dh-home-cards-contain-img dh-home-cards-contain-img-1"
            ></div>
          </div>
        </a>
      </ng-template>

      <ng-template carouselSlide>
        <a
          id="link"
          (click)="
            goRouter(
              !login
                ? '/servicio/concreto'
                : '/servicio/cotizacion-en-proceso-concreto'
            )
          "
        >
          <div
            class="dh-home-cards-contain"
            itemscope
            itemtype="https://schema.org/HomeAndConstructionBusiness"
          >
            <p
              class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
              itemprop="name"
            >
              {{ 'HOME.aecurePayment' | translate }}
            </p>
            <div
              class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
              itemprop="description"
            >
              {{ 'HOME.text2' | translate }}
            </div>
            <div
              loading="lazy"
              class="dh-home-cards-contain-img dh-home-cards-contain-img-3"
            ></div>
          </div>
        </a>
      </ng-template>

      <ng-template carouselSlide>
        <a id="link" href="tel:+525549667141">
          <div
            class="dh-home-cards-contain"
            itemscope
            itemtype="https://schema.org/HomeAndConstructionBusiness"
          >
            <p
              class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
              itemprop="name"
            >
              {{ 'HOME.24/7' | translate }}
            </p>
            <div
              class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
              itemprop="description"
            >
              {{ 'HOME.text3' | translate }}
            </div>
            <div
              loading="lazy"
              class="dh-home-cards-contain-img dh-home-cards-contain-img-4"
            ></div>
          </div>
        </a>
      </ng-template>

      <ng-template carouselSlide>
        <a id="link" href="https://www.dochmart.jobs/">
          <div
            class="dh-home-cards-contain"
            itemscope
            itemtype="https://schema.org/HomeAndConstructionBusiness"
          >
            <p
              class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
              itemprop="name"
            >
              {{ 'HOME.dochmartJobs' | translate }}
            </p>
            <div
              class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
              itemprop="description"
            >
              {{ 'HOME.jobsInfo' | translate }}
            </div>
            <div
              loading="lazy"
              class="dh-home-cards-contain-img dh-home-cards-contain-img-5"
            ></div>
          </div>
        </a>
      </ng-template>
    </owl-carousel-o>
  </div>

  <div *ngIf="!mobileCarrucel" class="dh-home-cards-min dh-ajust-width">
    <div class="dh-home-cards-min-box dh-flex-row-justific-space-between">
      <a
        id="link"
        (click)="
          goRouter(
            !login
              ? '/servicio/concreto'
              : '/servicio/cotizacion-en-proceso-concreto'
          )
        "
      >
        <div
          class="dh-home-cards-contain"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <p
            class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
            itemprop="name"
          >
            {{ 'HOME.quickDelivery' | translate }}
          </p>
          <div
            class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
            itemprop="description"
          >
            {{ 'HOME.text1' | translate }}
          </div>
          <div
            loading="lazy"
            class="dh-home-cards-contain-img dh-home-cards-contain-img-1"
          ></div>
        </div>
      </a>

      <a
        id="link"
        (click)="
          goRouter(
            !login
              ? '/servicio/concreto'
              : '/servicio/cotizacion-en-proceso-concreto'
          )
        "
      >
        <div
          class="dh-home-cards-contain"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <p
            class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
            itemprop="name"
          >
            {{ 'HOME.aecurePayment' | translate }}
          </p>
          <div
            class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
            itemprop="description"
          >
            {{ 'HOME.text2' | translate }}
          </div>
          <div
            loading="lazy"
            class="dh-home-cards-contain-img dh-home-cards-contain-img-3"
          ></div>
        </div>
      </a>

      <a id="link" href="tel:+525549667141">
        <div
          class="dh-home-cards-contain"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <p
            class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
            itemprop="name"
          >
            {{ 'HOME.24/7' | translate }}
          </p>
          <div
            class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
            itemprop="description"
          >
            {{ 'HOME.text3' | translate }}
          </div>
          <div
            loading="lazy"
            class="dh-home-cards-contain-img dh-home-cards-contain-img-4"
          ></div>
        </div>
      </a>

      <a id="link" href="https://www.dochmart.jobs/">
        <div
          class="dh-home-cards-contain"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <p
            class="dh-home-cards-contain-titule dh-text-card-titule-poppins dh-color-blue"
            itemprop="name"
          >
            {{ 'HOME.dochmartJobs' | translate }}
          </p>
          <div
            class="dh-home-cards-contain-text dh-flex-row-align-center-justific-center dh-text-big-poppins dh-color-grey-649"
            itemprop="description"
          >
            {{ 'HOME.jobsInfo' | translate }}
          </div>
          <div
            loading="lazy"
            class="dh-home-cards-contain-img dh-home-cards-contain-img-5"
          ></div>
        </div>
      </a>
    </div>
  </div>
</div>
