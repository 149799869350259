import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mobile-images-banner',
  templateUrl: './mobile-images-banner.component.html',
  styleUrls: ['./mobile-images-banner.component.scss'],
})
export class MobileImagesBannerComponent implements OnInit {
  constructor(private authService: AuthService) {}

  public isDragging = false;
  public login = false;

  isDrag(event: any) {
    this.isDragging = event.dragging;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: true,
    autoplay: true,
    navSpeed: 100,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };

  ngOnInit(): void {
    this.login = this.authService.onLogin();
  }
}
