import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from 'src/app/services/window.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-footer-user',
  templateUrl: './footer-user.component.html',
  styleUrls: ['./footer-user.component.scss'],
})
export class FooterUserComponent implements OnInit {
  public mobile = false;
  public login = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  constructor(
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private authService: AuthService,
  ) {
    const scrWidth = this.window.innerWidth;
    if (scrWidth <= 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  public goSocial(url: string): void {
    this.window.open(url);
  }

  public goRouter(url: string): void {
    this.router.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }

  public session: boolean = false;

  ngOnInit(): void {
    this.login = this.authService.onLogin();

     //=====================================================
    //session para mostrar la parte del footer
    let datsc =  localStorage.getItem('data');
    if ( datsc?.length == undefined ) 
    {
    this.session = false;
    }else {
      this.session = true;
    }
   //=====================================================

  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
