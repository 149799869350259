<div class="dh-header-app">
  <div class="dh-header-box dh-flex-row-align-center-justific-space-between">
    <div class="dh-header-box-l dh-flex-row-align-center-justific-space-between">
      <mat-icon (click)="goRouter('administrador/dashboard')"
        class="dh-header-box-l-logo dh-decoration-curosr-pinter dh-logo-mobile" svgIcon="ic-logo"></mat-icon>
    </div>

    <div class="dh-header-box-r dh-flex-row-align-center-justific-space-between">
    <div
      class="dh-header-box-r dh-flex-row-align-center-justific-space-between"
    >
      <div *ngIf="!mobile">
        <div class="dh-header-box-rol">
          <div class="dh-header-box-docoration-line"></div>
          <p class="dh-header-box-name">Administrador</p>
        </div>
      </div>

      <div *ngIf="!mobile"
        class="dh-cp-box dh-flex-row-align-center-justific-space-between dh-decoration-curosr-pinter">
        <div (click)="goMenuMovile()">
          <mat-icon *ngIf="mobile" class="dh-menu-icon" svgIcon="ic-menu"></mat-icon>
        </div>
      </div>

      <div *ngIf="!mobile && !loading"
        class="dh-profile dh-flex-row-align-center-justific-space-evenly dh-login-section">
        <img src="../../../../assets/icons/user-icon.svg" alt="icono de usuario" width="25px" height="25px" />
        <div [ngClass]="{ 'dh-text-profil': login, 'dh-text-profil-off': !login }"
          class="dh-text-profil dh-flex-column-justify-start">
          <p *ngIf="login" class="dh-text-profil-account dh-text-regular-poppins dh-color-grey-649">
            {{ 'HOME.account' | translate }}
          </p>
          <p [ngClass]="{ 'dh-decoration-curosr-pinter': !login }"
            class="dh-text-profil-name  dh-text-regular-big-poppins dh-color-blue" type="button"
            routerLink="/administrador/perfil">
            {{ login ? data.name + ' ' + data.surname : '' }}
          </p>
        </div>
        <mat-icon *ngIf="login" class="dh-icon-blue dh-decoration-curosr-pinter dh-arrow-display-info"
          svgIcon="ic-arrow-bottom" [matMenuTriggerFor]="menu"></mat-icon>
      </div>
    </div>
  </div>
  <mat-menu class="dh-contain-header-menu dh-profile-menu-header" #menu="matMenu">
    <div class="dh-icon-section">
      <mat-icon class="dh-icon-blue dh-decoration-curosr-pinter dh-icon-in-menu" svgIcon="ic-arrow-bottom"></mat-icon>
    </div>

    <button type="button" mat-button class="dh-menu-element">
      <a class="dh-menu-element-link dh-menu-element-link-effect" routerLink="/administrador/perfil" title=""
        routerLinkActive="active-link">
        <div class="dh-icon-profile dh-icon-desktop">
          <img width="20px" height="20px" src="./assets/icons/side-nav/provider/profile-active.svg"
            alt="perfil de proveedor" />
        </div>
        <p>{{ 'HOME.profile' | translate }}</p>
      </a>
    </button>

    <hr class="dh-line-profile-menu" />
    <button (click)="goToSignOff()" mat-menu-item
      class="dh-button-header-mat-menu-item dh-logout dh-menu-element-link-effect">
      <img src="../../../../assets/icons/logout-icon-blue.svg" class="dh-menu-icon dh-logout-icon" width="21px"
        height="21px" alt="Cotizar concretro premezclado" />
      <span class="dh-text-menu">{{ 'HOME.signOff' | translate }}</span>
    </button>
  </mat-menu>
</div>

<div *ngIf="mobile" class="dh-menu-mobile">
  <div class="dh-menu-mobile-row">
    <button type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/administrador/dashboard" title="" routerLinkActive="active-link">
        <div [ngClass]="movileSidebarIsOpen ? 'more-options-active' : 'dh-more-options-inactive'">
          <div class="dh-icon-dashboard dh-icon-mobile">
            <img width="39px" height="26.25px" src="./assets/icons/side-nav/administrator/dashboard.svg"
              alt="dashboard" />
          </div>
        </div>
      </a>
    </button>

    <button type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/administrador/seccion/solicitudes/" title="" routerLinkActive="active-link">
        <div [ngClass]="movileSidebarIsOpen ? 'more-options-active' : 'dh-more-options-inactive'">
          <div class="dh-icon-writting dh-icon-mobile">
            <img width="39px" height="35px" src="./assets/icons/side-nav/administrator/writting.svg"
              alt="solicitudes" />
          </div>
        </div>
      </a>
    </button>

    <button type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/administrador/proveedores" title="" routerLinkActive="active-link">
        <div [ngClass]="movileSidebarIsOpen ? 'more-options-active' : 'dh-more-options-inactive'">
          <div class="dh-icon-store dh-icon-mobile">
            <img
              width="39px"
              height="27px"
              src="./assets/icons/side-nav/administrator/store.svg"
              alt="detalles de pedidos de proveedor"
            />
          </div>
        </div>
      </a>
    </button>

    <button type="button" mat-button class="dh-menu-mobile-element">
      <a [routerLink]="['/administrador/seccion/prospectos']" title="" [ngClass]="{'active-link': isProspectsActive }">
        <div [ngClass]="movileSidebarIsOpen ? 'more-options-active' : 'dh-more-options-inactive'">
          <div class="dh-icon-person-in-document dh-icon-mobile">
            <img width="39px" height="33px" src="./assets/icons/side-nav/administrator/person-in-document.svg"
              alt="menú de administrador" />
          </div>
        </div>
      </a>
    </button>


    <button (click)="toggleSidebarMenu()" type="button" mat-button class="dh-menu-mobile-element">
      <a title="" routerLinkActive="active-link">
        <div class="dh-icon-more-options dh-icon-mobile" [class.sideMenuOptionActive]="isASideMenuOption()">
          <img width="39px" height="32.88px" [src]="!movileSidebarIsOpen && isASideMenuOption() ? './assets/icons/side-nav/administrator/more-options-middle-color-active.svg' : movileSidebarIsOpen
            ? './assets/icons/side-nav/administrator/more-options-active.svg'
            : './assets/icons/side-nav/administrator/more-options.svg'" alt=" más opciones" />
        </div>
      </a>
    </button>
  </div>
</div>
