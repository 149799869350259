import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  public mobile = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    if (isPlatformBrowser(this.platformId)) {
      const scrWidth = window.innerWidth;
      if (scrWidth < 1360) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  }

  constructor(
    public dialogCole: MatDialogRef<WelcomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {}

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }

  ngOnInit(): void {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1360) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
}
