import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BambuService {
  public cpData$ = new EventEmitter<any>();
  public upData$ = new EventEmitter<boolean>();

  public url = environment.rootUrl;

  private urlZips =
    'https://codigospostales.herokuapp.com/postal_code/suburbs/';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    @Inject(DOCUMENT) private dom: any,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public getZipCode(zip: string) {
    const authBambu = 'Bearer b4mbum0b1l3';
    return firstValueFrom(
      this.http.get(`${this.urlZips}${zip}`, {
        headers: { Authorization: authBambu },
      }),
    );
  }

  public emitCp(cp: string) {
    return this.cpData$.emit(cp);
  }

  public emitUpdate() {
    return this.upData$.emit(true);
  }

  public setCanonicalURL(url?: string) {
    const DATA = <HTMLElement>this.document.getElementById('canonical');
    if (DATA) {
      DATA.remove();
    }
    let canURL: string = url == undefined ? this.dom.URL : url;
    canURL = canURL.endsWith('/') ? canURL.slice(0, -1) : canURL;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('id', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  
  public async InformationQuoteId( id: any ): Promise<any> {
    try {
      return this.http.get(`${this.url}/api-v1/user/quote-information/${id}`, {
          headers: this.authService.getToken(),
        })
        .toPromise();
    } catch (error) {
      return error;
    }
  }




}
