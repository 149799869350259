import {
  InjectionToken,
  ClassProvider,
  FactoryProvider,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export const LOCAL_STORAGE = new InjectionToken('LocalStorageToken');

export abstract class LocalStorageRef {
  get nativeLocalStorage(): Storage {
    throw new Error('Not implemented.');
  }
}

export class BrowserLocalStorageRef extends LocalStorageRef {
  constructor() {
    super();
  }

  override get nativeLocalStorage(): Storage {
    return localStorage;
  }
}

export function localStorageFactory(
  browserLocalStorageRef: BrowserLocalStorageRef,
  platformId: Object,
): Storage | Object {
  if (isPlatformBrowser(platformId)) {
    return browserLocalStorageRef.nativeLocalStorage;
  }
  return new Object();
}

const browserLocalStorageProvider: ClassProvider = {
  provide: LocalStorageRef,
  useClass: BrowserLocalStorageRef,
};

const localSttorageProvider: FactoryProvider = {
  provide: LOCAL_STORAGE,
  useFactory: localStorageFactory,
  deps: [LocalStorageRef, PLATFORM_ID],
};

export const LOCALSTORAGE_PROVIDERS = [
  browserLocalStorageProvider,
  localSttorageProvider,
];
