<div *ngIf="session == false" > 
    <div class="dh-footer" *ngIf="!mobile">
        <div class="dh-footer-container">
            <div class="dh-footer-row">
                <div class="dh-footer-col dh-first-col">
                    <a href="https://play.google.com/store/apps/details?id=com.dochmart.dochmart_mobile&hl=es_CO&gl=US">
                        <div class="dh-footer-image-google">
                            <img src="../../../assets/img/Web-Footer-general_google.webp"
                                alt="Venta de concretro premezclado" />
                        </div>
                    </a>
                </div>
                <div class="dh-footer-col dh-col-mid">
                    <div class="dh-fotter-image-center-logo">
                        <img src="../../../assets/img/Web-Footer-general_logo.webp" alt="Comprar concreto premezclado" />
                    </div>
                </div>
                <div class="dh-footer-col dh-second-col">
                    <div class="dh-footer-info-title">
                        <p>{{ 'FOOTER.additionalInformation' | translate }}</p>
                    </div>
                    <div class="dh-footer-two-cols">
                        <div class="dh-footer-info">

                            <p (click)="scrollToTop()" routerLink="/proveedor/mas-informacion" class="dh-link">
                                {{ 'FOOTER.moreInfo' | translate }}
                            </p>

                            <p  (click)="scrollToTop()" routerLink="/proveedor/contactanos">
                                {{ 'FOOTER.contact' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dh-footer-bottom dh-color-white" [ngClass]="{
            'dh-flex-column-reverse-align-center-justific-flex-end': mobile,
            'dh-flex-row-align-center-justific-space-between dh-flex': !mobile
        }">
            <p class="dh-text-regular-small-poppins">
                <span class="dh-footer-rights">
                    {{ 'FOOTER.dochmart2022' | translate }}
                </span>
            </p>
            <div class="dh-footer-bottom-terms dh-footer-terms" [ngClass]="{
            'dh-flex-column-align-center-justific-space-around': mobile,
            'dh-flex-row-justific-space-between dh-text-regular-poppins': !mobile
            }">
                <a [routerLink]="['/proveedor/terminos-y-condiciones']" [queryParams]="{ seccion: 1 }"
                    class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
                    {{ 'FOOTER.terms' | translate }}
                </a>
                <a [routerLink]="['/proveedor/terminos-y-condiciones']" [queryParams]="{ seccion: 2 }"
                    class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
                    {{ 'FOOTER.privacy' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="session == false" > 
    <div class="dh-footer" *ngIf="mobile">
        <div class="dh-footer-container">
            <div class="dh-footer-row">
                <div class="dh-footer-image">
                    <img width="220px" height="87px" src="../../../assets/img/Web-Footer-general_logo.webp"
                        alt="Cotizar concreto premezclado" />
                </div>
                <div class="dh-footer-text-align">
                    <p class="dh-footer-info-title">
                        {{ 'FOOTER.additionalInformation' | translate }}
                    </p>
                    <a (click)="scrollToTop()" [owlRouterLink]="['/info/mas-informacion']">
                        <p class="dh-footer-info-text">
                            {{ 'FOOTER.moreInfo' | translate }}
                        </p>
                    </a>
                    <p class="dh-footer-info-text">
                        {{ 'FOOTER.contact' | translate }}
                    </p>
                </div>
                <div *ngIf="!mobile"  class="dh-footer-images">
                    <img width="170px" height="53px" src="../../../assets/img/Web-Footer-general_google.webp"
                        alt="Cotizar concreto premezclado" />
                </div>
            </div>
        </div>
        <div class="dh-footer-bottom dh-color-white" [ngClass]="{
            'dh-flex-column-reverse-align-center-justific-flex-end': mobile,
            'dh-flex-row-align-center-justific-space-between': !mobile
        }">
            <p class="dh-text-regular-small-poppins">
                {{ 'FOOTER.dochmart2022' | translate }}
            </p>
            <div class="dh-footer-bottom-terms" [ngClass]="{
            'dh-flex-column-align-center-justific-space-around': mobile,
            'dh-flex-row-justific-space-between dh-text-regular-poppins': !mobile
            }">
                <a routerLink="/proveedor/preguntas-frecuentes"
                    class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
                    {{ 'FOOTER.terms' | translate }}
                </a>
                <a routerLink="/proveedor/preguntas-frecuentes"
                    class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
                    {{ 'FOOTER.privacy' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
