import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { SignOffComponent } from '../../sign-off/sign-off.component';
import { MenuMobileComponent } from '../../menu-mobile/menu-mobile.component';
import { WINDOW } from 'src/app/services/window.service';
import { isPlatformBrowser } from '@angular/common';
import { ProductsDialogComponent } from 'src/app/provider/products/products-dialog/products-dialog.component';

@Component({
  selector: 'app-header-provider',
  templateUrl: './header-provider.component.html',
  styleUrls: ['./header-provider.component.scss'],
})
export class HeaderProviderComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private navigator: Router,
    public authService: AuthService,
    private userService: UserService,
    private provider: ProviderService,

    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private plataformId: any,
  ) {}

  public data: any;
  public login = false;
  public progressStep = 0;
  public initialHide = false;
  public loader = true;
  public loading = true;
  public mobile = false;
  public noNotshowRoleLabel = true;
  public showMoreOptions = false;
  public userCanChangeShippingStatus = false;
  public userCanSeeOrderDetail = false;
  public showProducts = false;
  public completeConfig = false;
  public isOperatorUr = false;
  public onDelivery = false;
  public currentDeliveryId: any = null;

  public roles = '';
  userModuleOptions: any = [];

  ngOnInit(): void {
    this.noNotshowRoleLabel = this.authService.userHasMoreThanARole();
    this.showMoreOptions = this.authService.canUserSeeProfile();
    this.userModuleOptions = this.authService.getUserModuleOptions();
    this.roles = this.authService.userRoles();
    this.showProducts = this.authService.userCanCreateProducts();
    this.isOperatorUr = this.authService.hasTypeUserLogged('operatorUR');
    this.onDelivery = this.authService.checkIsOnDelivery();
    this.currentDeliveryId = this.authService.getCurrentDeliveryId();

    this.getUser();

    if (this.authService.typesOfUsers.includes('provider')) {
      this.getProgress();
    }

    this.loader = false;

    if (isPlatformBrowser(this.plataformId)) {
      const scrWidth = this.window.innerWidth;
      if (scrWidth <= 1111) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  public logout() {
    if (this.mobile) {
      const dialogRef = this.dialog.open(SignOffComponent, {
        minWidth: '90%',
        height: '100%',
        maxHeight: '300px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    }
  }

  public getUser() {
    this.loader = true;
    this.login = this.authService.onLogin();
    if (this.login) {
      this.userService.getUser().then((ress: any) => {
        if (ress === null) {
          this.data = {
            name: '',
            surname: '',
          };
          this.login = false;
        } else {
          this.data = ress.data.user;
          this.loading = false;
        }
      });
    }
  }

  public getProgress() {
    this.provider
      .getProgressStatus()
      .then((ress: any) => {
        if (ress.data.status === null) {
          this.initialHide = false;
        } else if (ress.data.status.step_number < 5) {
          this.initialHide = false;
        } else if (ress.data.status.step_number >= 5) {
          this.initialHide = true;
        }

        if (ress.data.status !== null) {
          if (ress.data.status.step_completation_date) {
            this.completeConfig = true;
          }
        } else {
          this.completeConfig = false;
        }

        this.loader = false;
      })
      .catch((error) => {
       
      });
  }

  public goMenuMovile() {
    this.dialog.open(MenuMobileComponent, {
      panelClass: 'dh-menu-dialog',
      width: '423px',
      minHeight: '360px',
      disableClose: true,
      autoFocus: false,
      data: {
        login: this.login,
        name: this.login ? this.data.name + ' ' + this.data.surname : '',
      },
    });
  }

  public goToSignOff() {
    const dialogRef = this.dialog.open(SignOffComponent, {
      disableClose: true,
      autoFocus: false,
      data: {},
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        localStorage.removeItem('data');
        this.goRouter('/acceso/iniciar-sesion');
        this.authService.checkLogin();
      }
    });
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }

  isButtonClicked = false;

  openSideNav() {
    this.isButtonClicked = !this.isButtonClicked;
  }

  public showCreateProductModal() {
    this.dialog
      .open(ProductsDialogComponent, {
        width: '1260px',
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        data: {
          dataRow: {},
        },
      })
      .afterClosed()
      .subscribe((dataResponse) => {});
  }
}
