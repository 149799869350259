import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingUsersInteractionService {
  loandingUsers = new Subject<any>();
  loandingUsersEmiter$ = this.loandingUsers.asObservable();

  notifyLoading(variable: boolean) {
    this.loandingUsers.next(variable);
  }
}
