import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private statusLogin = new BehaviorSubject<boolean>(false);
  public url = environment.rootUrl;
  public user: any;
  public dataAux: any;
  public data: any = { name: '', apellido: '' };
  public header: any;
  public isBrowser: boolean;
  public headersAux = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  public type_user = '';
  public typesOfUsers: string[] = [];
  public initialHideHeader = false;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) plataformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(plataformId);
  }

  public changeDeliveryStatus(status: boolean, deliveryId: any): boolean {
    const userData = localStorage.getItem('data');
    if (userData === null) {
      return false;
    }
    const dataParse = JSON.parse(userData);
    if (dataParse) {
      dataParse.user.onDelivery = status;
      dataParse.user.deliveryId = deliveryId;
      localStorage.setItem('data', JSON.stringify(dataParse));
      return true;
    }
    return false;
  }

  public checkLogin() {
    const userData = localStorage.getItem('data');
    if (userData === null) {
      return false;
    }

    const dataParse = JSON.parse(userData);
    if (dataParse) {
      this.typesOfUsers = dataParse.user.type_user;
      return true;
    }

    this.typesOfUsers = [];
    return false;
  }

  public checkIsOnDelivery() {
    const userData = localStorage.getItem('data');
    if (userData === null) {
      return false;
    }
    const dataParse = JSON.parse(userData);
    if (dataParse) {
      console.log(dataParse);
      return dataParse.user.onDelivery;
    }
    return false;
  }

  public getCurrentDeliveryId() {
    const userData = localStorage.getItem('data');
    if (userData === null) {
      return false;
    }
    const dataParse = JSON.parse(userData);
    if (dataParse) {
      return dataParse.user.deliveryId;
    }
    return null;
  }

  public getToken(): any {
    if (this.isBrowser) {
      this.dataAux = localStorage.getItem('data');
      this.data = JSON.parse(this.dataAux);
      this.header = {
        Authorization: `Bearer ${this.data.accessToken}`,
      };
      return this.header;
    }
  }

  public onLogin(): any {
    if (this.isBrowser) {
      this.dataAux = localStorage.getItem('data');
      this.data = JSON.parse(this.dataAux);
      if (this.data != null) {
        return true;
      }
      return false;
    }
  }

  public getUserModuleOptions(): any {
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const userModuleOptions = userJsonData.user.modules;
      return userModuleOptions;
    }
  }

  public canUserSeeProducts(): boolean {
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;

      if (
        typesOfUser.includes('operationalMaster') ||
        typesOfUser.includes('plantManager') ||
        typesOfUser.includes('provider') ||
        typesOfUser.includes('weigher')
      ) {
        userCan = true;
      }
    }

    return userCan;
  }

  public userCanCreateUser(): boolean {
    const CREATEUSER = 'create:user';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(CREATEUSER);
    }
    return userCan;
  }

  public userCanUpdateUser(): boolean {
    const UPDATEUSER = 'update:user';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(UPDATEUSER);
    }
    return userCan;
  }

  public userCanDeleteUser(): boolean {
    const DELETEUSER = 'delete:user';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(DELETEUSER);
    }
    return userCan;
  }

  public userCanCreateProducts(): boolean {
    const CREATEPRODUCTSERVICES = 'add:services';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(CREATEPRODUCTSERVICES);
    }
    return userCan;
  }

  public userCanUpdateProducts(): boolean {
    const UPDATEPRODUCTSERVICES = 'update:services';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(UPDATEPRODUCTSERVICES);
    }
    return userCan;
  }

  public userCanChangeShippingStatus(): boolean {
    const UPDATEDELIVEYSHIPPINGSTATUS = 'update:delivery-shipping-status';
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(UPDATEDELIVEYSHIPPINGSTATUS);
    }
    return userCan;
  }

  public userCanChangeOrderStatus(): boolean {
    const UPDATEORDERSTATUS = 'update:order-shipping-status';
    let userCan = false;
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(UPDATEORDERSTATUS);
    }
    return userCan;
  }

  public userCanSeeOrderDetail(): boolean {
    const SEEORDERDETAIL = 'read:order';
    let userCan = false;
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(SEEORDERDETAIL);
    }
    return userCan;
  }

  public userCanAddOperators(): boolean {
    const ADDOPERATORS = 'read:order';
    let userCan = false;
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      userCan = userJsonData.user.scopes.includes(ADDOPERATORS);
    }
    return userCan;
  }

  public userHasMoreThanARole(): boolean {
    let moreThanOne = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;

      if (typesOfUser.length > 1) {
        moreThanOne = true;
      }
    }

    return moreThanOne;
  }

  public canUserSeeProfile(): boolean {
    let userCan = false;
    const userStringData = localStorage.getItem('data');

    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;

      if (typesOfUser.includes('provider')) {
        userCan = true;
      }
    }

    return userCan;
  }

  public hasModulePermission(permission: string): boolean {
    const data = localStorage.getItem('data');

    if (!data) {
      return false;
    }

    const jsonData = JSON.parse(data);

    if (
      !jsonData ||
      !jsonData.user ||
      !jsonData.user.scopes ||
      !Array.isArray(jsonData.user.scopes)
    ) {
      return false;
    }

    return jsonData.user.scopes.includes(permission);
  }

  public hasLogged(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken ? true : false;
    }
    return false;
  }

  public userHasRole(userType: string): boolean {
    if (!this.isBrowser) {
      return false;
    }
    const data = localStorage.getItem('data');
    if (data === null) {
      return false;
    }
    const dataParse = JSON.parse(data);
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;

      if (dataParse.accessToken && typesOfUser.includes(userType)) {
        return true;
      }
    }
    return false;
  }

  public userRoles(): string {
    const data = localStorage.getItem('data');
    if (data === null) {
      return '';
    }
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;
      const spanishTypesOfUser = typesOfUser.map((item: any) => {
        switch (item) {
          case 'provider':
            return 'Proveedor';
          case 'operationalMaster':
            return 'Administrador Provedor';
          case 'plantManager':
            return 'Jefe de planta';
          case 'weigher':
            return 'Pesador';
          case 'administrativeAssistant':
            return 'Auxiliar administrativo';
          case 'administrative':
            return 'Administrativo';
          case 'operatorUR':
            return 'Operador de unidad revolvedora';
          case 'administrator':
            return 'Administrador';
          default:
            return '';
        }
      });
      return spanishTypesOfUser.join(' | ');
    }
    return '';
  }

  public hasTypeUserLogged(userType: string): boolean {
    if (!this.isBrowser) {
      return false;
    }

    const data = localStorage.getItem('data');
    if (data === null) {
      return false;
    }

    const dataParse = JSON.parse(data);
    const userStringData = localStorage.getItem('data');
    if (userStringData) {
      const userJsonData = JSON.parse(userStringData);
      const typesOfUser = userJsonData.user.type_user;
      switch (userType) {
        case 'provider':
          return dataParse.accessToken && typesOfUser.includes('provider');
        case 'operationalMaster':
          return (
            dataParse.accessToken && typesOfUser.includes('operationalMaster')
          );
        case 'retailBuyer':
          return dataParse.accessToken && typesOfUser.includes('retailBuyer');
        case 'plantManager':
          return dataParse.accessToken && typesOfUser.includes('plantManager');
        case 'weigher':
          return dataParse.accessToken && typesOfUser.includes('weigher');
        case 'administrativeAssistant':
          return (
            dataParse.accessToken &&
            typesOfUser.includes('administrativeAssistant')
          );
        case 'administrative':
          return (
            dataParse.accessToken && typesOfUser.includes('administrative')
          );
        case 'operatorUR':
          return dataParse.accessToken && typesOfUser.includes('operatorUR');
        case 'administrator':
          return dataParse.accessToken && typesOfUser.includes('administrator');
        default:
          return false;
      }
    }

    return false;
  }

  public hasLoggedUser(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken ? true : false;
    }
    return false;
  }

  public hasLoggedProvider(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken && dataParse.user.type_user === 'provider'
        ? true
        : false;
    }
    return false;
  }
  public hasLoggedOperator(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken && dataParse.user.type_user === 'operator'
        ? true
        : false;
    }
    return false;
  }

  public hasLoggedAdmin(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken &&
        dataParse.user.type_user === 'administrator'
        ? true
        : false;
    }
    return false;
  }

  public hasLoggedRetailBuyer(): boolean {
    if (this.isBrowser) {
      const data = localStorage.getItem('data');
      if (data === null) {
        return false;
      }
      const dataParse = JSON.parse(data);
      return dataParse.accessToken &&
        dataParse.user.type_user.includes('retailBuyer')
        ? true
        : false;
    }
    return false;
  }

  public async login(user: any) {
    return firstValueFrom(
      this.http.post(`${this.url}/api-v1/auth/login`, user),
    );
  }

  public crateUser(user: any) {
    return firstValueFrom(
      this.http.post(`${this.url}/api-v1/auth/register`, user),
    );
  }
  public crateProvider(user: any) {
    return firstValueFrom(
      this.http.post(`${this.url}/api-v1/auth/register-provider`, user),
    );
  }

  public async verifyUser(token: string): Promise<any> {
    try {
      return this.http
        .get(`${this.url}/api-v1/auth/verify/${token}`)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public async verifyCellPhoneNumber(number: any) {
    return firstValueFrom(
      this.http.post(`${this.url}/api-v1/auth/verify/number-phone`, number),
    );
  }

  public async resetPassword(body: any): Promise<any> {
    try {
      return this.http
        .post(`${this.url}/api-v1/auth/forgot-password/`, body)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public async changePassword(body: any, token: string): Promise<any> {
    try {
      return this.http
        .put(`${this.url}/api-v1/auth/new-password/${token}`, body)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public async resendCodeEmail(body: any): Promise<any> {
    try {
      return this.http
        .post(`${this.url}/api-v1/auth/resend-code/`, body)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public async sendInvateEmail(body: any): Promise<any> {
    try {
      return this.http
        .post(`${this.url}/api-v1/auth/send-invate/`, body)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public setStatusLogin(newStatus: boolean) {
    this.statusLogin.next(newStatus);
  }

  public getStatusLogin(): Observable<boolean> {
    return this.statusLogin.asObservable();
  }

  public async lstBancos(): Promise<any> {
    if (this.isBrowser) {
      this.dataAux = localStorage.getItem('data');
      this.data = JSON.parse(this.dataAux);
      this.header = {
        Authorization: `Bearer ${this.data.accessToken}`,
      };
    }
    try {
      return this.http
        .get(`${this.url}/api-v1/provider/banks`, {
          headers: this.header,
        })
        .toPromise();
    } catch (error) {
      return null;
    }
  }
}
