import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { CpComponent } from './cp/cp.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { RecoverNewPasswordComponent } from './recover-new-password/recover-new-password.component';

@NgModule({
  declarations: [
    HomeComponent,
    CpComponent,
    ConfirmEmailComponent,
    RecoverNewPasswordComponent,
  ],
  imports: [CommonModule, HomeRoutingModule, SharedModule],
})
export class HomeModule {}
