<div class="dh-home-background">
  <app-images-banner [mobile]="mobile"></app-images-banner>
  <br />
  <div class="dh-home">
    <div
      itemscope
      itemtype="https://schema.org/HomeAndConstructionBusiness"
      class="dh-center-flex dh-mobile-offers"
      *ngIf="mobile"
      (click)="
        !login
          ? goRouter('/servicio/concreto')
          : goRouter('/servicio/cotizacion-en-proceso-concreto')
      "
    >
      <div>
        <div class="dh-free-quote">
          <span itemprop="name" class="dh-quote">
            {{ 'HOME.quoteMobile' | translate }}
            <span class="dh-quote-free-blue">
              &nbsp;{{ 'HOME.quote2Mobile' | translate }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <br />

    <div class="dh-home-body">
      <div
        class="dh-home-bony-titule dh-flex-row-align-center-justific-center dh-text-regular-titule-poppins dh-our-categories"
      >
        <b class="dh-color-black-323">
          {{ 'HOME.categories1' | translate }}
        </b>
        &nbsp;
        <b class="dh-color-blue">
          {{ 'HOME.categories2' | translate }}
        </b>
      </div>

      <div
        class="dh-home-card-box dh-flex-body-cards dh-cards-container"
        [ngClass]="{
          'dh-flex-row-justific-space-around dh-flex-flex-wrap': !mobile,
          'dh-flex-column-justify-center': mobile
        }"
      >
        <div
          class="dh-home-card"
          [ngClass]="{ 'dh-card-margin': !mobile }"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <div class="dh-home-card-front">
            <img
              itemprop="image"
              loading="lazy"
              src="../../../assets/img/Web-home-modal-servicios_concreto.webp"
              alt="Cemento Premezclado"
            />
          </div>
          <div class="dh-home-card-back">
            <div class="dh-home-card-back-data">
              <div class="dh-home-card-titule dh-flex-column-justify-center">
                <p class="dh-text-little-titule-poppins" itemprop="name">
                  {{ 'HOME.concrete' | translate }}
                </p>
              </div>
              <div class="dh-home-card-line dh-flex-column-justify-center">
                <p
                  class="dh-text-big-poppins dh-color-grey-649 dh-description"
                  itemprop="description"
                >
                  {{ 'HOME.concreteText' | translate }}
                </p>
              </div>
              <button
                (click)="
                  !login
                    ? goRouter('/servicio/concreto')
                    : goRouter('/servicio/cotizacion-en-proceso-concreto')
                "
                mat-button
                class="dh-button-card"
              >
                {{ 'HOME.quoteConcrete' | translate }}
                <mat-icon class="dh-arrow-icon" svgIcon="ic-arrow"></mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          class="dh-home-card"
          [ngClass]="{ 'dh-card-margin': !mobile }"
          itemscope
          itemtype="https://schema.org/HomeAndConstructionBusiness"
        >
          <div class="dh-home-card-front">
            <div class="dh-home-card-front">
              <img
                loading="lazy"
                itemprop="image"
                src="../../../assets/img/Web-home-modal-servicios_bombeo.webp"
                alt="Servicio de Bombeo"
              />
            </div>
          </div>
          <div class="dh-home-card-back">
            <div class="dh-home-card-back-data">
              <div class="dh-home-card-titule dh-flex-column-justify-center">
                <p class="dh-text-little-titule-poppins" itemprop="name">
                  {{ 'HOME.pumping' | translate }}
                </p>
              </div>
              <div class="dh-home-card-line dh-flex-column-justify-center">
                <p
                  class="dh-text-big-poppins dh-color-grey-649 dh-description"
                  itemprop="description"
                >
                  {{ 'HOME.pumpingText' | translate }}
                </p>
              </div>
              <button
                (click)="
                  !login
                    ? goRouter('/servicio/concreto')
                    : goRouter('/servicio/cotizacion-en-proceso-concreto')
                "
                mat-button
                class="dh-button-card"
              >
                {{ 'HOME.quoteBamb' | translate }}
                <mat-icon class="dh-arrow-icon" svgIcon="ic-arrow"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dh-margin-top-bottom-banner">
    <div class="dh-flex-row-justific-center dh-margin-top-bottom">
      <p
        class="dh-home-cards-min-text dh-text-regular-titule-poppins dh-margin-bottom-reduce"
      >
        <b class="dh-color-black-323">{{ 'HOME.whyBuy1' | translate }}</b>
        <b class="dh-color-blue">{{ 'HOME.whyBuy2' | translate }}</b>
      </p>
    </div>
    <app-bottom-banner></app-bottom-banner>
  </div>

  <div
    *ngIf="mobile && false"
    class="dh-button-footer-div dh-flex-row-justific-center"
  >
    <button
      mat-button
      class="dh-button-footer dh-color-white dh-text-botton-poppins"
    >
      {{ 'FOOTER.accessSuppliers' | translate }}
    </button>
  </div>
</div>
