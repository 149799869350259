import { Component } from '@angular/core';
import { NavbarAdministratorService } from 'src/app/services/navbar-administrator.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-layout-administrator',
  templateUrl: './layout-administrator.component.html',
  styleUrls: ['./layout-administrator.component.scss'],
})
export class LayoutAdministratorComponent {
  movileSidebarIsOpen = false;
  sidebarIsOpen = false;
  constructor(
    private navbarService: NavbarAdministratorService,
    private sidebarService: SidebarService,
  ) {
    this.navbarService.isOpen$.subscribe((response) => {
      this.movileSidebarIsOpen = response;
    });
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
  }
}
