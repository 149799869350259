import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BambuService } from 'src/app/services/bambu.service';
import { CP_MASK } from 'src/app/shared/input-mask.directive';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cp',
  templateUrl: './cp.component.html',
  styleUrls: ['./cp.component.scss'],
})
export class CpComponent implements OnInit {
  public pcMask = CP_MASK;
  public cpForm: any;
  public cpFormError = '';

  constructor(
    public dialogCole: MatDialogRef<CpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public bambuService: BambuService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.cpForm = new UntypedFormGroup({
      cp: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const dataAux: any = localStorage.getItem('cp');
      if (dataAux) {
        const cpAux = JSON.parse(dataAux);
        this.cpForm.controls.cp.setValue(cpAux.cp);
      }
    }
  }

  public closeModal(): void {
    if (isPlatformBrowser(this.platformId)) {
      const dataAux: any = localStorage.getItem('cp');
      if (dataAux) {
        const cpAux = JSON.parse(dataAux);
        this.dialogCole.close(cpAux.cp);
      } else {
        this.dialogCole.close('');
      }
    }
  }

  public emitCp() {
    if (isPlatformBrowser(this.platformId)) {
      this.bambuService
        .getZipCode(this.cpForm.controls.cp.value)
        .then(async () => {
          localStorage.setItem(
            'cp',
            JSON.stringify({
              cp: this.cpForm.controls.cp.value,
            }),
          );
          this.bambuService.emitCp(this.cpForm.controls.cp.value);
          this.dialogCole.close(this.cpForm.controls.cp.value);
        })
        .catch(() => {
          this.cpFormError = 'Código postal invalido';
          this.cpForm.get('cp').setErrors({ incorrect: true });
        });
    }
  }
}
