import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-regular-search-section',
  templateUrl: './regular-search-section.component.html',
  styleUrls: ['./regular-search-section.component.scss'],
})
export class RegularSearchSectionComponent implements OnInit {
  @Input() placeholderText = '';
  @Input() calendarFilterOnlyToDesktop = false;
  @Input() filterable = true;
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateRangeChanges: EventEmitter<any> = new EventEmitter<any>();

  public isCalendarIconColorActive = false;
  ngOnInit(): void {
    this.initRangeFormSuscription();
  }

  public toggleCalendarStyleState() {
    this.isCalendarIconColorActive = !this.isCalendarIconColorActive;
  }
  public rangeForm = new FormGroup({
    start: new FormControl<Date | null>(null, Validators.required),
    end: new FormControl<Date | null>(null, Validators.required),
  });

  public initRangeFormSuscription() {
    this.rangeForm.valueChanges.subscribe(($event) => {
      this.dateRangeChanges.emit($event);
      if (this.rangeForm.valid) {
        this.isCalendarIconColorActive = true;
      } else {
        this.isCalendarIconColorActive = false;
      }
    });
  }

  public searchText(event: any) {
    const searchText = event.target.value.toString().toLowerCase().trim();
    this.searchTextChange.emit(searchText);
  }
}
