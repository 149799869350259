import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlantInformationService {
  public url = environment.rootUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  public registerPlant(plantInfomation: any): Observable<any> {
    return this.http.post(
      `${this.url}/api-v1/provider/register-plant`,
      plantInfomation,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public getStates(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/estados`, {
      headers: this.authService.getToken(),
    });
  }

  public getCity(stateId: string): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/municipios/` + stateId, {
      headers: this.authService.getToken(),
    });
  }

  public getDistricts(cirtyId: string): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/colonias/` + cirtyId, {
      headers: this.authService.getToken(),
    });
  }

  public getPlants(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/plants`, {
      headers: this.authService.getToken(),
    });
  }
}
