import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  public token: any = '';
  public verify = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastrService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.authService
      .verifyUser(this.token)
      .then((ress) => {
        if (ress !== null) {
          this.verify = true;
        }
      })
      .catch((error) => {
        if (error.error.data.userNotFound) {
          this.toastService.error(error.error.data.userNotFound, 'Error', {
            disableTimeOut: false,
            timeOut: 4000,
            tapToDismiss: false,
            positionClass: 'toast-top-right',
            toastClass: 'toast-icon custom-toast-error',
          });
          return;
        }

        if (error.error.data.alreadyVerificated) {
          this.toastService.error(
            error.error.data.alreadyVerificated,
            'Error',
            {
              disableTimeOut: false,
              timeOut: 4000,
              tapToDismiss: false,
              positionClass: 'toast-top-right',
              toastClass: 'toast-icon custom-toast-error',
            },
          );
          return;
        }

        if (error.error.data.expiratedToken) {
          this.toastService.error(error.error.data.expiratedToken, 'Error', {
            disableTimeOut: false,
            timeOut: 4000,
            tapToDismiss: false,
            positionClass: 'toast-top-right',
            toastClass: 'toast-icon custom-toast-error',
          });
          return;
        }

        this.toastService.error('Algo salió mal', 'Error', {
          disableTimeOut: false,
          timeOut: 4000,
          tapToDismiss: false,
          positionClass: 'toast-top-right',
          toastClass: 'toast-icon custom-toast-error',
        });
      });
  }

  public goLogin(): void {
    this.router.navigate(['acceso']);
    document.documentElement.scrollTop = 0;
  }
}
