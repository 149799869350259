import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-operator',
  templateUrl: './layout-operator.component.html',
  styleUrls: ['./layout-operator.component.scss'],
})
export class LayoutOperatorComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
