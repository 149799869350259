<div class="dh-menu-dialog-contain">
  <div
    class="dh-menu-dialog-header dh-flex-row-align-center-justific-space-between"
  >
    <mat-icon class="dh-menu-dialog-logo" svgIcon="ic-logo"></mat-icon>
    <mat-icon
      (click)="closeModal(false)"
      class="dh-menu-dialog-close dh-decoration-a"
      svgIcon="ic-close"
    ></mat-icon>
  </div>
  <div class="dh-menu-body dh-flex-column-align-center-justific-space-between">
    <div
      *ngIf="!data.login"
      class="dh-menu-body-null dh-text-regular-big-poppins dh-color-blue"
    >
      <p (click)="goRoute('acceso/registro')">
        {{ 'HOME.EMAIL.login' | translate }}
      </p>
      <p (click)="goRoute('acceso/registro/register')">
        {{ 'HOME.EMAIL.register' | translate }}
      </p>
    </div>

    <mat-accordion *ngIf="this.data.login">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="dh-text-regular-poppins $texts-grey-649">
            <mat-icon svgIcon="ic-profile"></mat-icon>
            &nbsp;
            {{ this.data.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="dh-menu-dialog-panel dh-flex-column dh-text-regular-big-poppins dh-color-blue"
        >
          <p (click)="goRoute('perfil/')">{{ 'HOME.profile' | translate }}</p>
          <p (click)="goRoute('acceso/registro')">
            {{ 'HOME.SINGOFF.signOff' | translate }}
          </p>
          <p (click)="goRoute('/servicio/cotizaciones')">
            {{ 'HOME.quote' | translate }}
          </p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="dh-menu-line"></div>

    <p
      (click)="goRoute('')"
      class="dh-menu-start dh-color-blue dh-text-regular-big-poppins"
    >
      {{ 'HOME.start' | translate }}
    </p>

    <div class="dh-menu-line"></div>

    <button
      (click)="
        data.login
          ? goRoute('/servicio/cotizacion-en-proceso-concreto')
          : goRoute('/servicio/concreto')
      "
      mat-button
      class="dh-button-home-card dh-text-botton-poppins dh-color-blue"
    >
      {{ 'HOME.quoteConcreteNow' | translate }}
      <mat-icon class="dh-arrow-icon" svgIcon="ic-arrow"></mat-icon>
    </button>

    <button
      (click)="
        data.login
          ? goRoute('servicion/cemento/cotizacion-en-proceso-cemento')
          : goRoute('servicio/cemento')
      "
      mat-button
      class="dh-button-home-card dh-text-botton-poppins dh-color-blue"
    >
      {{ 'QUOTE-CEMENT.quote' | translate }}
      <mat-icon class="dh-arrow-icon" svgIcon="ic-arrow"></mat-icon>
    </button>
  </div>
</div>
