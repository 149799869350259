
<div *ngIf=" session == false ">
  <div class="dh-footer" *ngIf="!mobile">
    <div class="dh-footer-container">
      <div class="dh-footer-row">
        <div *ngIf="!mobile" class="dh-footer-col dh-first-col">
          <a href="https://play.google.com/store/apps/details?id=com.dochmart.dochmart_mobile&hl=es_CO&gl=US">
            <div class="dh-footer-image-google">
              <img src="../../../assets/img/Web-Footer-general_google.webp" alt="Venta de concretro premezclado" />
            </div>
          </a>
        </div>
        <div class="dh-footer-col dh-col-mid">
          <div class="dh-fotter-image-center-logo">
            <img src="../../../assets/img/Web-Footer-general_logo.webp" alt="Comprar concreto premezclado" />
          </div>
        </div>
        <div class="dh-footer-col dh-second-col">
          <div class="dh-footer-info-title">
            <p>{{ 'FOOTER.additionalInformation' | translate }}</p>
          </div>
          <div class="dh-footer-two-cols">
            <div class="dh-footer-more">
              <span (click)="
                  goRouter(
                    !login
                      ? '/servicio/concreto'
                      : '/servicio/cotizacion-en-proceso-concreto'
                  )
                ">
                <p class="dh-link">
                  {{ 'FOOTER.concret' | translate }}
                </p>
              </span>
              <span (click)="
                  goRouter(
                    !login
                      ? '/servicio/concreto'
                      : '/servicio/cotizacion-en-proceso-concreto'
                  )
                ">
                <p class="dh-link">
                  {{ 'FOOTER.bombs' | translate }}
                </p>
              </span>
              <span (click)="goRouter('/info/mas-informacion')">
                <p class="dh-more-info">
                  {{ 'FOOTER.moreInfo' | translate }}
                </p>
              </span>
            </div>
            <div class="dh-footer-info">
              <span (click)="goRouter('/info/conocer-mas')">
                <p class="dh-link">
                  {{ 'FOOTER.learnMore' | translate }}
                </p>
              </span>
              <p class="dh-link" (click)="goRouter('/info/contacto')">
                {{ 'FOOTER.contact' | translate }}
              </p>
              <span (click)="goRouter('/info/preguntas-frecuentes')">
                <p class="dh-link">
                  {{ 'FOOTER.questions' | translate }}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dh-footer-bottom dh-color-white" [ngClass]="{
        'dh-flex-column-reverse-align-center-justific-flex-end': mobile,
        'dh-flex-row-align-center-justific-space-between dh-flex': !mobile
      }">
      <p class="dh-text-regular-small-poppins">
        <span class="dh-footer-rights">
          {{ 'FOOTER.dochmart2022' | translate }}
        </span>
      </p>
      <div class="dh-footer-bottom-terms dh-footer-terms" [ngClass]="{
          'dh-flex-column-align-center-justific-space-around': mobile,
          'dh-flex-row-justific-space-between dh-text-regular-poppins': !mobile
        }">
        <a [routerLink]="['/info/terminos-y-condiciones']" [queryParams]="{ seccion: 1 }"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
          {{ 'FOOTER.terms' | translate }}
        </a>
        <a [routerLink]="['/info/terminos-y-condiciones']" [queryParams]="{ seccion: 2 }"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
          {{ 'FOOTER.privacy' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf=" session == false ">
  <div class="dh-footer" *ngIf="mobile">
    <div class="dh-footer-container">
      <div class="dh-footer-row">
        <div class="dh-footer-image">
          <img width="220px" height="87px" src="../../../assets/img/Web-Footer-general_logo.webp"
            alt="Cotizar concreto premezclado" />
        </div>
        <div class="dh-footer-text-align">
          <p class="dh-footer-info-title">
            {{ 'FOOTER.additionalInformation' | translate }}
          </p>

          <a (click)="scrollToTop()" [owlRouterLink]="
              !login
                ? ['/servicio/concreto']
                : ['/servicio/cotizacion-en-proceso-concreto']
            ">
            <p class="dh-footer-more-text">
              {{ 'FOOTER.concret' | translate }}
            </p>
          </a>
          <a (click)="scrollToTop()" [owlRouterLink]="
              !login
                ? ['/servicio/concreto']
                : ['/servicio/cotizacion-en-proceso-concreto']
            ">
            <p class="dh-footer-more-text">
              {{ 'FOOTER.bombs' | translate }}
            </p>
          </a>
          <a (click)="scrollToTop()" [owlRouterLink]="['/info/conocer-mas']">
            <p class="dh-footer-info-text">
              {{ 'FOOTER.learnMore' | translate }}
            </p>
          </a>
          <a (click)="scrollToTop()" routerLink="/inf/mas-informacion" [owlRouterLink]="['/info/mas-informacion']">
            <p class="dh-footer-info-text">
              {{ 'FOOTER.moreInfo' | translate }}
            </p>
          </a>
          <p class="dh-footer-info-text">
            {{ 'FOOTER.contact' | translate }}
          </p>
          <a (click)="scrollToTop()" [owlRouterLink]="['/info/preguntas-frecuentes']">
            <p class="dh-footer-info-text">
              {{ 'FOOTER.questions' | translate }}
            </p>
          </a>
        </div>
        <div class="dh-footer-images">
          <img width="170px" height="53px" src="../../../assets/img/Web-Footer-general_google.webp"
            alt="Cotizar concreto premezclado" />
        </div>
      </div>
    </div>
    <div class="dh-footer-bottom dh-color-white" [ngClass]="{
        'dh-flex-column-reverse-align-center-justific-flex-end': mobile,
        'dh-flex-row-align-center-justific-space-between': !mobile
      }">
      <p class="dh-text-regular-small-poppins">
        {{ 'FOOTER.dochmart2022' | translate }}
      </p>
      <div class="dh-footer-bottom-terms" [ngClass]="{
          'dh-flex-column-align-center-justific-space-around': mobile,
          'dh-flex-row-justific-space-between dh-text-regular-poppins': !mobile
        }">
        <a href="https://www.dochmart.com/info/terminos-y-condiciones/Términos y condiciones"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
          {{ 'FOOTER.terms' | translate }}
        </a>
        <a href="https://www.dochmart.com/info/terminos-y-condiciones/Aviso de privacidad"
          class="dh-decoration-a dh-decoration-curosr-pinter dh-color-white">
          {{ 'FOOTER.privacy' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>