<div class="dh-search-section">
    <div class="dh-search-container" [ngClass]="!filterable ? 'dh-is-not-filterable' : ''">
        <div class="dh-search-input-container">
            <mat-form-field class="dh-search" appearance="outline">
                <span matPrefix></span>
                <input [disabled]="!filterable" (input)="searchText($event)" class="searching-text-input" matInput
                    [placeholder]="placeholderText | translate" />
                <button class="searching-btn" width="24px" height="24px" matSuffix
                    mat-button><mat-icon>search</mat-icon></button>
            </mat-form-field>
        </div>
        <div class="dh-date-picker-container"
            [ngClass]="calendarFilterOnlyToDesktop ? 'dh-only-to-desktop' : ''">
            <mat-form-field class=" custom-mat-date-range-form-field"
                [ngClass]="isCalendarIconColorActive ? 'calendar-icon-color-active' : ''">
                <mat-label>{{ 'ADMINISTRATOR.dataRangePlaceholder' | translate }}</mat-label>
                <mat-date-range-input [disabled]="!filterable" [formGroup]="rangeForm" [rangePicker]="picker">
                    <input formControlName="start" matStartDate placeholder="Fecha inicial">
                    <input formControlName="end" matEndDate placeholder="Fecha final">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix class="dh-custom-datepicker-toggle"
                    [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>