<div class="dh-go-logon dh-flex-column-align-center">
  <div class="dh-go-logon-close dh-flex-row-justific-flex-end">
    <mat-icon
      (click)="closeModal(false)"
      class="dh-go-logon-close-logo dh-decoration-curosr-pinter"
      svgIcon="ic-close"
    ></mat-icon>
  </div>

  <mat-icon class="dh-go-logon-logo" svgIcon="ic-logo"></mat-icon>

  <div class="dh-go-logon-box dh-text-big-poppins dh-color-black-323">
    {{ data.text }}
  </div>

  <button
    (click)="closeModal(true)"
    mat-button
    class="dh-button-general dh-color-white"
  >
    Comienza ahora
  </button>
</div>
