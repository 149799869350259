import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit {
  public dataForm: any;

  constructor(
    public dialogCole: MatDialogRef<BusinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.dataForm = new UntypedFormGroup({
      business: new UntypedFormControl('', [Validators.required]),
    });
    this.dataForm.controls.business.setValue(
      this.data.user.data.company.name_company,
    );
  }

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }

  public async upgradeUser(): Promise<any> {
    const body = {
      company: this.dataForm.controls.business.value,
    };
    await this.userService.upgradeUser(body).then((ress) => {
      if (ress) {
        this.closeModal(true);
      }
    });
  }
}
