import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-sidebar-administrator',
  templateUrl: './sidebar-administrator.component.html',
  styleUrls: ['./sidebar-administrator.component.scss'],
})
export class SidebarAdministratorComponent implements OnInit {
  constructor(
    private navigator: Router,
    private sidebarService: SidebarService,
  ) {}

  public sidebarIsOpen = false;
  public initialUrlChecked = false;
  public isProspectsActive = false;
  public navigateToUrl(link: string) {
    this.navigator.navigate([`/administrador/${link}`]);
  }

  ngOnInit(): void {
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
    this.subscribeToUrlChanges();
    this.checkInitialUrl();
  }

  subscribeToUrlChanges() {
    this.navigator.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkUrl();
      }
    });
  }

  checkInitialUrl() {
    const currentUrl = this.navigator.url;
    this.isProspectsActive = this.checkUrlMatch(currentUrl);
  }

  checkUrl() {
    const currentUrl = this.navigator.url;
    this.isProspectsActive = this.checkUrlMatch(currentUrl);
  }

  checkUrlMatch(url: string): boolean {
    const URL_TO_ACTVE_PROSPECT_ICON = [
      '/administrador/seccion/prospectos',
      '/administrador/seccion/prospecto-detalle',
    ];
    return URL_TO_ACTVE_PROSPECT_ICON.some((urlToCheck) =>
      url.includes(urlToCheck),
    );
  }

  public handleSideBarMenu() {
    this.sidebarService.toggle();
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
  }
}
