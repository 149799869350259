<div class="dh-data-dialog">
  <div
    class="dh-flex-row-align-inherit-justific-space-between dh-data-dialog-header"
  >
    <p class="dh-text-big-poppins dh-color-black-323">
      {{ 'PROFILE.titulePassword' | translate }}
    </p>
    <mat-icon
      class="dh-data-dialog-header-icon dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal(false)"
    ></mat-icon>
  </div>
  <div class="dh-data-dialog-body dh-flex-column-justific-space-between">
    <div [formGroup]="dataForm">
      <div class="dh-mat-form-field-general dh-mat-form-field-general-margin">
        <mat-form-field appearance="outline" class="dh-input">
          <mat-label>{{ 'PROFILE.password' | translate }}</mat-label>
          <input
            formControlName="oldPassword"
            matInput
            [placeholder]="'PROFILE.password' | translate"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            class="dh-button-password"
          >
            <img
              *ngIf="!hide"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility.svg"
              alt="Cotizar concreto premezclado"
            />
            <img
              *ngIf="hide"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility_off.svg"
              alt="Venta de concreto premezclado"
            />
          </button>
          <mat-error
            class=""
            *ngIf="dataForm.get('oldPassword').hasError('required')"
          >
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error
            class=""
            *ngIf="dataForm.get('oldPassword').hasError('minlength')"
          >
            {{ 'AUTH.errorMinLength' | translate }}
          </mat-error>
          <mat-error
            class=""
            *ngIf="dataForm.get('oldPassword').hasError('incorrect')"
          >
            {{ errorMess }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dh-mat-form-field-general dh-mat-form-field-general-margin">
        <mat-form-field appearance="outline" class="dh-input">
          <mat-label>{{ 'PROFILE.upgradePassword' | translate }}</mat-label>
          <input
            (keyup)="ckeckPasswordForm()"
            formControlName="newPassword"
            matInput
            [placeholder]="'PROFILE.upgradePassword' | translate"
            [type]="hide2 ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide2 = !hide2"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide2"
            class="dh-button-password"
          >
            <img
              *ngIf="!hide2"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility.svg"
              alt="Cotizar concreto premezclado"
            />
            <img
              *ngIf="hide2"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility_off.svg"
              alt="Venta de concreto premezclado"
            />
          </button>
          <mat-error *ngIf="dataForm.get('newPassword').hasError('required')">
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="dataForm.get('newPassword').hasError('pattern')">
            {{ 'AUTH.format' | translate }}
          </mat-error>
          <mat-error *ngIf="dataForm.get('newPassword').hasError('minlength')">
            {{ 'AUTH.errorMinLength' | translate }}
          </mat-error>
          <mat-error *ngIf="dataForm.get('newPassword').hasError('incorrect')">
            {{ 'HOME.PASSWORD.confirmPassword' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dh-mat-form-field-general dh-mat-form-field-general-margin">
        <mat-form-field appearance="outline" class="dh-input">
          <mat-label>{{ 'PROFILE.confirmPassword' | translate }}</mat-label>
          <input
            (keyup)="ckeckPasswordForm()"
            formControlName="passwordConfirm"
            matInput
            [placeholder]="'PROFILE.confirmPassword' | translate"
            [type]="hide3 ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide3 = !hide3"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide3"
            class="dh-button-password"
          >
            <img
              *ngIf="!hide3"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility.svg"
              alt="Cotizar concreto premezclado"
            />
            <img
              *ngIf="hide3"
              width="25px"
              height="25px"
              src="../../../assets/icons/visibility_off.svg"
              alt="Venta de concreto premezclado"
            />
          </button>
          <mat-error
            *ngIf="dataForm.get('passwordConfirm').hasError('required')"
          >
            {{ 'AUTH.errorRequiredField' | translate }}
          </mat-error>
          <mat-error
            *ngIf="dataForm.get('passwordConfirm').hasError('pattern')"
          >
            {{ 'AUTH.format' | translate }}
          </mat-error>
          <mat-error
            *ngIf="dataForm.get('passwordConfirm').hasError('minlength')"
          >
            {{ 'AUTH.errorMinLength' | translate }}
          </mat-error>
          <mat-error
            *ngIf="dataForm.get('passwordConfirm').hasError('incorrect')"
          >
            {{ 'HOME.PASSWORD.confirmPassword' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="dh-button">
        <button
          [disabled]="
            dataForm.status === 'INVALID' ||
            this.dataForm.controls.passwordConfirm.value !==
              this.dataForm.controls.newPassword.value
          "
          (click)="upgradeUser()"
          mat-button
          class="dh-button-general dh-text-regular-big-poppins dh-color-white dh-password-button dh-password-save"
        >
          <b>{{ 'PROFILE.save' | translate }}</b>
        </button>
      </div>
    </div>
  </div>
</div>
