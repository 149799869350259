import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
// Traslate
import { HttpLoaderFactory } from './shared/shared.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './home/home.module';
import { PermissionsGuard } from './guard/permissions.guard';
import { PermissionsGuardOff } from './guard/permissionsoff.guard';
import { WINDOW_PROVIDERS } from './services/window.service';
import { LOCALSTORAGE_PROVIDERS } from './services/localStorage.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { CustomDochmartToastComponent } from './shared/components/custom-dochmart-toast/custom-dochmart-toast.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    HomeModule,
    MatNativeDateModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: CustomDochmartToastComponent,
      closeButton: true,
      positionClass: 'toast-top-right',
      newestOnTop: false,
    }),
  ],
  providers: [
    PermissionsGuard,
    PermissionsGuardOff,
    WINDOW_PROVIDERS,
    LOCALSTORAGE_PROVIDERS,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
