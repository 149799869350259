import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CAPITAL_LETTER } from 'src/app/shared/data';
import { WINDOW } from 'src/app/services/window.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recover-new-password',
  templateUrl: './recover-new-password.component.html',
  styleUrls: ['./recover-new-password.component.scss'],
})
export class RecoverNewPasswordComponent implements OnInit {
  public hide = false;
  public hide2 = false;
  public confirm = false;
  public token: any = '';
  public newPassword: any;
  public passwordsMatch = false;
  public loader = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.newPassword = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(CAPITAL_LETTER),
        Validators.minLength(8),
      ]),
      passwordConfirm: new FormControl('', [
        Validators.required,
        Validators.pattern(CAPITAL_LETTER),
        Validators.minLength(8),
      ]),
    });
  }

  ckeckPasswordF() {
    const password = this.newPassword.get('password').value;
    const passwordConfirm = this.newPassword.get('passwordConfirm').value;

    if (password.length >= 8 && passwordConfirm.length >= 8) {
      this.passwordsMatch = password === passwordConfirm;
    } else {
      this.passwordsMatch = false;
    }
  }

  public ckeckPasswordForm(): void {
    if (
      this.newPassword.controls.password.value.length >= 8 &&
      this.newPassword.controls.passwordConfirm.value.length >= 8
    ) {
      this.ressForm();
      if (
        this.newPassword.controls.password.value !==
        this.newPassword.controls.passwordConfirm.value
      ) {
        this.errorForm();
      }
    }
  }

  public ressForm(): void {
    this.newPassword.controls.password.status = 'VALID';
    this.newPassword.controls.passwordConfirm.status = 'VALID';
    this.newPassword.status = 'VALID';
  }

  public errorForm(): void {
    this.newPassword.controls.passwordConfirm.setErrors({ incorrect: true });
    this.newPassword.controls.password.setErrors({ incorrect: true });
  }

  public async newPass(): Promise<any> {
    this.loader = true;
    await this.authService
      .changePassword(
        { newPassword: this.newPassword.controls.password.value },
        this.token,
      )
      .then(async (ress) => {
        if (ress) {
          this.loader = false;
          this.confirm = !this.confirm;
        }
      })
      .catch((error) => {
        this.toastService.error('Algo salió mal', 'Error', {
          disableTimeOut: false,
          timeOut: 4000,
          tapToDismiss: false,
          positionClass: 'toast-top-right',
          toastClass: 'toast-icon custom-toast-error',
        });
      });
  }

  public goHome(): void {
    this.router.navigate(['']);
    document.documentElement.scrollTop = 0;
  }

  public goRouter(url: string): void {
    this.router.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }

  public goNewRouter(url: string): void {
    this.window.open(url);
    document.documentElement.scrollTop = 0;
  }
}
