import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LOCAL_STORAGE } from './localStorage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public url = environment.rootUrl;
  public user: any;
  public dataAux: any;
  public data: any;
  public header: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
  ) { }

  public async getUser() {
    try {
      return await firstValueFrom(
        this.http.get(`${this.url}/api-v1/user`, {
          headers: this.authService.getToken(),
        }),
      );
    } catch (error) {
      this.localStorage.removeItem('data');
      this.router.navigate(['auth']);
      document.documentElement.scrollTop = 0;
      return error;
    }
  }

  public async upgradeUser(user: any): Promise<any> {
    try {
      return this.http
        .put(`${this.url}/api-v1/user`, user, {
          headers: this.authService.getToken(),
        })
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public async newPassword(body: any): Promise<any> {
    return firstValueFrom(
      this.http.put(`${this.url}/api-v1/user/newPassword`, body, {
        headers: this.authService.getToken(),
      }),
    );
  }

  public getDeliveryStarted(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/user/on-delivery`, {
      headers: this.authService.getToken(),
    });
  }
}
