<div *ngIf="loader" class="dh-loading dh-loading-opacity">
  <app-flat-loader></app-flat-loader>
</div>

<div class="dh-profil profile-component-container ">
  <div class="dh-profil-titule dh-flex-column-justify-center">
    <div class="dh-header-profile-mobile">
      <p class="dh-text-regular-titule-poppins dh-color-black-323">
        {{ 'PROFILE.titule' | translate }}
      </p>

      <div class="dh-log-out-section" *ngIf="mobile">
        <img
          width="18px"
          height="18px"
          src="../../../assets/icons/logout-icon-blue.svg"
          alt="Venta de concreto premezclado"
        />
        <p (click)="logout()" class="dh-log-out">
          {{ 'HOME.signOff' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="dh-profil-body"
    [ngClass]="{
      'dh-flex-row-justific-center': !mobile,
      'dh-flex-column-align-center': mobile
    }"
  >
    <div
      [ngClass]="{
        'dh-flex-row': !mobile,
        'dh-flex-column-align-center': mobile
      }"
    >
      <div class="dh-flex-column">
        <div class="dh-profil-body-card">
          <div class="dh-profil-body-header dh-flex-row-justific-space-between">
            <p
              class="dh-flex-row-align-center-justific-center dh-text-little-titule-poppins dh-color-black-323"
            >
              <span class="dh-titles-profile">
                {{ 'PROFILE.dataUser' | translate }}
              </span>
            </p>
            <div *ngIf="!mobile" class="dh-primary-btn-container">
              <button (click)="goEditData()" class="dh-edit-desktop">
                <p>
                  {{ 'PROFILE.edit' | translate }}
                </p>
              </button>
            </div>
          </div>
          <div class="dh-profil-body-card-edit-data-user-box" *ngIf="user">
            <div class="dh-profil-body-card-edit-data-user dh-margin-bottom">
              <p class="dh-text-semibold-poppins dh-color-black-323 dh-label">
                {{ user.name }}
              </p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.names' | translate }}
              </p>
            </div>
            <div class="dh-profil-body-card-edit-data-user dh-margin-bottom">
              <p class="dh-text-semibold-poppins dh-color-black-323 dh-label">
                {{ user.surname }} {{ user.second_surname }}
              </p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.surnames' | translate }}
              </p>
            </div>
            <div class="dh-profil-body-card-edit-data-user dh-margin-bottom">
              <p class="dh-text-semibold-poppins dh-color-black-323 dh-label">
                {{ user.phone }}
              </p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.phone' | translate }}
              </p>
            </div>
            <div class="dh-edit-section" *ngIf="mobile">
              <button (click)="goEditData()" class="dh-edit-button">
                <p class="dh-text-medium-poppins dh-color-blue">
                  {{ 'PROFILE.edit' | translate }}
                </p>
              </button>
            </div>
          </div>
        </div>
        <div class="dh-profil-body-card">
          <div class="dh-profil-body-header dh-flex-row-justific-space-between">
            <p
              class="dh-flex-row-align-center-justific-center dh-text-little-titule-poppins dh-color-black-323"
            >
              <span class="dh-titles-profile">
                {{ 'PROFILE.bill' | translate }}
              </span>
            </p>
          </div>
          <div
            class="dh-profil-body-card-edit-data-user-box dh-password-card"
            *ngIf="user"
          >
            <div class="dh-profil-body-card-edit-bill dh-margin-bottom">
              <p class="dh-text-semibold-poppins dh-color-black-323">
                {{ user.email }}
              </p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A dh-email">
                {{ 'PROFILE.email' | translate }}
              </p>
            </div>
            <p
              (click)="upgradePassword()"
              class="dh-text-medium-poppins dh-change-password"
            >
              {{ 'PROFILE.newPassword' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="dh-flex-column">
        <div class="dh-profil-body-card">
          <div class="dh-profil-body-header dh-flex-row-justific-space-between">
            <p
              class="dh-flex-row-align-center-justific-center dh-text-little-titule-poppins dh-color-black-323"
            >
              <span class="dh-titles-profile">
                {{ 'PROFILE.businessTitule' | translate }}
              </span>
            </p>
            <div *ngIf="!mobile" class="dh-primary-btn-container">
              <button (click)="goEditBusiness()" class="dh-edit-desktop">
                <p>
                  {{ 'PROFILE.edit' | translate }}
                </p>
              </button>
            </div>
          </div>
          <div class="dh-profil-body-card-edit-data-user-box" *ngIf="user">
            <div
              class="dh-profil-body-card-edit-business dh-flex-column-justific-space-between dh-flex-start"
            >
              <p class="dh-text-semibold-poppins dh-color-black-323">
                {{ user.company.name_company }}
              </p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.business' | translate }}
              </p>
            </div>

            <div class="dh-edit-section" *ngIf="mobile">
              <button (click)="goEditBusiness()" class="dh-edit-button">
                <p class="dh-text-medium-poppins dh-color-blue">
                  {{ 'PROFILE.edit' | translate }}
                </p>
              </button>
            </div>
          </div>
        </div>
        <div class="dh-profil-body-card dh-last-quotes">
          <div class="dh-profil-body-header dh-flex-row-justific-space-between">
            <p
              class="dh-flex-row-align-center-justific-center dh-text-little-titule-poppins dh-color-black-323"
            >
              <span class="dh-titles-profile">
                {{ 'PROFILE.order' | translate }}
              </span>
            </p>
            <div *ngIf="!mobile" class="dh-primary-btn-container">
              <button
                (click)="goRouter('/servicio/cotizaciones')"
                class="dh-see-all-desktop"
              >
                <p>
                  {{ 'PROFILE.seeAll' | translate }}
                </p>
              </button>
            </div>
          </div>
          <div class="dh-profil-body-card-edit-data-user-box">
            <div
              class="dh-profil-body-card-edit-order dh-flex-column-justific-space-between"
            >
              <p class="dh-text-semibold-poppins dh-color-black-323">--</p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.nOeder' | translate }}
              </p>
            </div>
            <div
              class="dh-profil-body-card-edit-order dh-flex-column-justific-space-between dh-provider-section"
            >
              <p class="dh-text-semibold-poppins dh-color-black-323">-- --</p>
              <p class="dh-text-regular-poppins dh-color-grey-A6A">
                {{ 'PROFILE.supplier' | translate }}
              </p>
            </div>

            <div class="dh-flex-row-align-center-justific-space-between">
              <div
                class="dh-profil-body-card-edit-order dh-flex-column-justific-space-between"
              >
                <p class="dh-text-semibold-poppins dh-color-black-323">$--</p>
                <p class="dh-text-regular-poppins dh-color-grey-A6A">
                  {{ 'PROFILE.total' | translate }}
                </p>
              </div>
              <div
                class="dh-profil-body-card-edit-order dh-flex-column-justific-space-between"
              >
                <p class="dh-text-semibold-poppins dh-color-black-323">
                  --/--/--
                </p>
                <p class="dh-text-regular-poppins dh-color-grey-A6A">
                  {{ 'PROFILE.date' | translate }}
                </p>
              </div>
            </div>

            <div class="dh-profil-body-card-edit-order-button">
              <div class="dh-status">
                {{ 'PROFILE.delivered' | translate }}
              </div>
              <div class="dh-edit-section" *ngIf="mobile">
                <button
                  (click)="goRouter('/servicio/cotizaciones')"
                  class="dh-edit-button dh-button-see-all"
                >
                  <p class="dh-text-medium-poppins dh-color-blue">
                    {{ 'PROFILE.seeAll' | translate }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="view" class="dh-profil-body-img-gradiant-color">
      <div class="dh-profil-body-img-gradiant">
        <div class="dh-profil-body-img" loading="lazy"></div>
      </div>
    </div>
  </div>
</div>
