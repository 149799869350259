import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BambuService } from 'src/app/services/bambu.service';
import { WINDOW } from '../../services/window.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public mobile = false;
  isDragging = false;
  public login = false;

  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  isDrag(event: any) {
    this.isDragging = event.dragging;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: true,
    autoplay: true,
    navSpeed: 100,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };
  customOptionsTop: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    nav: true,
    autoplay: true,
    navText: ['', ''],
    animateIn: 'slideInLeft',
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  constructor(
    private navigator: Router,
    private titleService: Title,
    @Inject(WINDOW) private window: Window,
    private bambuService: BambuService,
    private authService: AuthService,
  ) {
    const scrWidth = this.window.innerWidth;
    if (scrWidth <= 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.titleService.setTitle(
      'Dochmart - Compra concreto premezclado por metro cúbico, entregado directamente en tu obra',
    );
  }

  ngOnInit(): void {
    this.bambuService.setCanonicalURL();
    this.login = this.authService.onLogin();
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
