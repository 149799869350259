<div class="dh-search-section regular-text-search-field">
    <div class="dh-search-container" [ngClass]="!filterable ? 'dh-is-not-filterable' : ''">
        <div class="dh-search-input-container" [ngClass]="!userCanCreateUser ? 'dh-full-search-space' : ''">
            <mat-form-field class="dh-search" appearance="outline">
                <span matPrefix></span>
                <input [disabled]="!filterable" (input)="searchText($event)" class="searching-text-input" matInput
                    [placeholder]="placeholderText | translate" />
                <button class="searching-btn" width="24px" height="24px" matSuffix
                    mat-button><mat-icon>search</mat-icon></button>
            </mat-form-field>
        </div>
        <button *ngIf="userCanCreateUser"   routerLink='/proveedor/usuarios/crear-usuario'  class="dh-create-user-btn">
            <div>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.7786 8.54158H8.52865V14.7916H6.44531V8.54158H0.195312V6.45825H6.44531V0.208252H8.52865V6.45825H14.7786V8.54158Z"
                        fill="#304FFE" />
                </svg>
            </div>
            <p>{{ 'PROVIDER.usersComponent.addUser' | translate }}</p>
        </button>
    </div>
    
</div>