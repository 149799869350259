import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CpComponent } from 'src/app/home/cp/cp.component';
import { MatDialog } from '@angular/material/dialog';
import { SignOffComponent } from '../../sign-off/sign-off.component';
import { MenuMobileComponent } from '../../menu-mobile/menu-mobile.component';
import { AuthService } from 'src/app/services/auth.service';
import { SubSink } from 'subsink';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { WINDOW } from 'src/app/services/window.service';
import { LOCAL_STORAGE } from '../../../services/localStorage.service';
import { Subject, filter, interval, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public subscriptions = new SubSink();
  public mobile = false;
  public login = false;
  public loading = true;
  public data: any = {
    name: '',
    apellido: '',
  };
  public cp = '';
  public administratorRoute = false;
  userModuleOptions: any = [];
  public currentDeliveryId: any = null;
  private destroy$: Subject<void> = new Subject<void>();

  URLWITHOUTPROFILEOPTIONS: string[] = ['/servicio/proceso-de-pago'];
  public toHideProfileSection = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  constructor(
    private dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastrService,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(PLATFORM_ID) private plataformId: any,
  ) {
    this.currentDeliveryId = this.authService.getCurrentDeliveryId();
    if (isPlatformBrowser(plataformId)) {
      const scrWidth = this.window.innerWidth;
      if (scrWidth <= 1111) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
      const dataAux: any = localStorage.getItem('cp');
      if (dataAux) {
        const cpAux = JSON.parse(dataAux);
        this.cp = cpAux.cp;
      }
    }
  }

  public logout() {
    if (this.mobile) {
      const dialogRef = this.dialog.open(SignOffComponent, {
        minWidth: '90%',
        height: '100%',
        maxHeight: '300px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    }
  }

  public verifyModuleOption(moduleOptions: string) {
    if (this.userModuleOptions) {
      return this.userModuleOptions.includes(moduleOptions);
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.toHideProfileSection = this.URLWITHOUTPROFILEOPTIONS.includes(
      this.navigator.url,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.getUser();

    if (this.authService.checkLogin()) {
      if (this.authService.checkIsOnDelivery()) {
        this.navigator.navigate([
          '/servicio/entregas/' + this.authService.getCurrentDeliveryId(),
        ]);
      }

      if (!this.authService.checkIsOnDelivery()) {
        interval(4000)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.userService.getDeliveryStarted().subscribe({
              next: (response: any) => {
                this.destroy$.next();
                this.destroy$.complete();
                if (response.data.onDelivery) {
                  this.authService.changeDeliveryStatus(
                    true,
                    response.data.deliveryId,
                  );

                  location.reload();

                  setTimeout(() => {
                    this.navigator.navigate([
                      '/servicio/entregas/' + response.data.deliveryId,
                    ]);
                  }, 4000);
                }
              },
              error: (error) => {
                if (error.error.data.ordersNotFound) {
                  return;
                }

                if (error.error.data.userNotFound) {
                  this.toastService.error(
                    error.error.data.userNotFound,
                    'Error',
                    {
                      disableTimeOut: false,
                      timeOut: 4000,
                      tapToDismiss: false,
                      positionClass: 'toast-top-right',
                      toastClass: 'toast-icon custom-toast-error',
                    },
                  );
                  return;
                }
                if (error.error.data.unauthorized) {
                  this.toastService.error(
                    error.error.data.unauthorized,
                    'Error',
                    {
                      disableTimeOut: false,
                      timeOut: 4000,
                      tapToDismiss: false,
                      positionClass: 'toast-top-right',
                      toastClass: 'toast-icon custom-toast-error',
                    },
                  );
                  return;
                }

                this.toastService.error('Algo salió mal', 'Error', {
                  disableTimeOut: false,
                  timeOut: 4000,
                  tapToDismiss: false,
                  positionClass: 'toast-top-right',
                  toastClass: 'toast-icon custom-toast-error',
                });
              },
            });
          });
      }
    }
    this.navigator.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.toHideProfileSection = this.URLWITHOUTPROFILEOPTIONS.includes(
          event.url,
        );
      });

    this.userModuleOptions = this.authService.getUserModuleOptions();
    this.authService.getStatusLogin().subscribe((status) => {
      if (status == true) {
        this.login = status;
        this.getUser();
      }
    });
    this.navigator.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (url.startsWith('/administrador')) {
          this.administratorRoute = true;
        } else if (url.startsWith('/servicio/proceso-de-pago')) {
          this.administratorRoute = true;
        } else {
          this.administratorRoute = false;
        }
      }
    });
    const currentPath = this.window.location?.pathname;
    if (currentPath.startsWith('/administrador')) {
      this.administratorRoute = true;
    } else if (currentPath.startsWith('/servicio/proceso-de-pago')) {
      this.administratorRoute = true;
    }
  }

  public getUser() {
    this.login = this.authService.onLogin();
    if (this.login) {
      this.userService
        .getUser()
        .then((ress: any) => {
          if (ress === null) {
            this.data = {
              name: '',
              surname: '',
            };
            this.login = false;
          } else {
            this.data = ress.data.user;
          }
        })
        .catch((error) => {
          if (error.error.data.internalServerError) {
            this.toastService.error(
              error.error.data.internalServerError,
              'Error',
              {
                disableTimeOut: false,
                timeOut: 4000,
                tapToDismiss: false,
                positionClass: 'toast-top-right',
                toastClass: 'toast-icon custom-toast-error',
              },
            );
            return;
          }
          this.toastService.error('Algo salió mal', 'Error', {
            disableTimeOut: false,
            timeOut: 4000,
            tapToDismiss: false,
            positionClass: 'toast-top-right',
            toastClass: 'toast-icon custom-toast-error',
          });
        })
        .finally(() => (this.loading = false));
    }
  }

  public goToDetails() {
    if (!this.mobile) {
      const dialogRef = this.dialog.open(CpComponent, {
        minWidth: '580px',
        minHeight: '360px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        this.cp = result;
      });
    } else {
      const dialogRef = this.dialog.open(CpComponent, {
        width: '328px',
        height: '390px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        this.cp = result;
      });
    }
  }

  public goToSignOff() {
    if (!this.mobile) {
      const dialogRef = this.dialog.open(SignOffComponent, {
        minWidth: '49px',
        minHeight: '385px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    } else {
      const dialogRef = this.dialog.open(SignOffComponent, {
        width: '238px',
        height: '351px',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.localStorage.removeItem('data');
          this.goRouter('/acceso/iniciar-sesion');
          this.authService.checkLogin();
        }
      });
    }
  }

  public goMenuMovile() {
    this.dialog.open(MenuMobileComponent, {
      panelClass: 'dh-menu-dialog',
      width: '423px',
      minHeight: '360px',
      disableClose: true,
      autoFocus: false,
      data: {
        login: this.login,
        name: this.login ? this.data.name + ' ' + this.data.surname : '',
      },
    });
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }
}
