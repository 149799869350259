import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignOffComponent } from '../../sign-off/sign-off.component';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MenuMobileComponent } from '../../menu-mobile/menu-mobile.component';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from 'src/app/services/window.service';
import { NavbarAdministratorService } from 'src/app/services/navbar-administrator.service';

@Component({
  selector: 'app-header-administrator',
  templateUrl: './header-administrator.component.html',
  styleUrls: ['./header-administrator.component.scss'],
})
export class HeaderAdministratorComponent implements OnInit {
  public mobile = false;
  public login = false;
  public loading = true;
  public isProspectsActive = false;
  public data: any = {
    name: '',
    surname: '',
  };
  public movileSidebarIsOpen = false;
  constructor(
    private dialog: MatDialog,
    private navigator: Router,
    public authService: AuthService,
    private userService: UserService,
    private navbarService: NavbarAdministratorService,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private plataformId: any,
  ) {
    this.subscribeToUrlChanges();
  }

  public initialUrlChecked = false;

  isASideMenuOption(): boolean {
    return this.router.url.startsWith('/administrador/clientes');
  }
  public goToSignOff() {
    const dialogRef = this.dialog.open(SignOffComponent, {
      disableClose: true,
      autoFocus: false,
      data: {},
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        localStorage.removeItem('data');
        this.goRouter('/acceso/iniciar-sesion');
        this.authService.checkLogin();
      }
    });
  }

  subscribeToUrlChanges() {
    this.navigator.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkUrl();
      }
    });
  }

  checkInitialUrl() {
    const currentUrl = this.navigator.url;
    this.isProspectsActive = this.checkUrlMatch(currentUrl);
    this.initialUrlChecked = true;
  }

  checkUrl() {
    const currentUrl = this.navigator.url;
    this.isProspectsActive = this.checkUrlMatch(currentUrl);
  }

  checkUrlMatch(url: string): boolean {
    const URL_TO_ACTVE_PROSPECT_ICON = [
      '/administrador/seccion/prospectos',
      '/administrador/seccion/prospecto-detalle',
    ];
    return URL_TO_ACTVE_PROSPECT_ICON.some((urlToCheck) =>
      url.includes(urlToCheck),
    );
  }

  public goRouter(url: string): void {
    this.navigator.navigate([`${url}`]);
    document.documentElement.scrollTop = 0;
  }

  isButtonClicked = false;

  openSideNav() {
    this.isButtonClicked = !this.isButtonClicked;
  }

  toggleSidebarMenu() {
    this.navbarService.toggle();
    this.navbarService.isOpen$.subscribe((response) => {
      this.movileSidebarIsOpen = response;
    });
  }

  public goMenuMovile() {
    this.dialog.open(MenuMobileComponent, {
      panelClass: 'dh-menu-dialog',
      width: '423px',
      minHeight: '360px',
      disableClose: true,
      autoFocus: false,
      data: {
        login: this.login,
        name: this.login ? this.data.name + ' ' + this.data.surname : '',
      },
    });
  }
  ngOnInit(): void {
    this.subscribeToUrlChanges();
    this.checkInitialUrl();
    this.getUser();
    this.authService.getStatusLogin().subscribe((status) => {
      if (status == true) {
        this.login = status;
        this.getUser();
      }
    });

    if (isPlatformBrowser(this.plataformId)) {
      const scrWidth = this.window.innerWidth;
      if (scrWidth <= 1111) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): any {
    const scrWidth = this.window.innerWidth;
    if (scrWidth < 1111) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  public getUser() {
    this.login = this.authService.onLogin();
    if (this.login) {
      this.userService.getUser().then((ress: any) => {
        if (ress === null) {
          this.data = {
            name: '',
            surname: '',
          };
          this.login = false;
        } else {
          this.data = ress.data.user;
        }
        this.loading = false;
      });
    }
  }
}
