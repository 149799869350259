<mat-sidenav-container [ngClass]="sidebarIsOpen ? 'sidenav-short-mode' : ''">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    fixedInViewport="true"
    fixedTopGap="64"
  >
  <!-- operationalMaster: ['booking', 'orders', 'products', 'users', 'binnacle'], -->

    <mat-nav-list>
      <div class="sidenav-btn-container">
        <button (click)="handleSideBarMenu()" class="sidenav-btn-handler">
          <img
            width="24px"
            height="24px"
            src="./assets/icons/side-nav/administrator/left-arrow.svg"
            alt=""
          />
        </button>
        <button (click)="handleSideBarMenu()" class="sidenav-btn-close-handler">
          <img
            width="24px"
            height="24px"
            src="./assets/icons/side-nav/administrator/right-arrow.svg"
            alt=""
          />
        </button>
      </div>
      <a
        mat-list-item
        class="sidenav-item"
        routerLink="/proveedor/reservas"
        routerLinkActive="active-link"
      >
        <div class="dh-icon-calendar dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/calendar.svg"
            alt="reservar entrega"
          />
        </div>
        <span class="nav-text">{{ 'HOME.quote'  | translate }}</span>
        <span class="selection-bar"></span>
      </a>
      <a
      *ngIf="userModuleOptions.includes('binnacle')"
        mat-list-item
        class="sidenav-item"
        routerLink="/proveedor/proyectos/"
        routerLinkActive="active-link
        "
      >
        <div class="dh-icon-menu dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/close-book.svg"
            alt="munú de proveedor"
          />
        </div>
        <span class="nav-text">{{ 'PROVIDER.navegationOptions.log' | translate }}</span>
        <span class="selection-bar"></span>
      </a>
      <a
      *ngIf="userModuleOptions.includes('orders')"
        mat-list-item
        class="sidenav-item"
        routerLink="/proveedor/orders"
        routerLinkActive="active-link"
      >
        <div class="dh-icon-tasks dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/tasks.svg"
            alt="detalles de pedidos de proveedor"
          />
        </div>
        <span class="nav-text">{{ 'PROVIDER.navegationOptions.orders' | translate }}</span>
        <span class="selection-bar"></span>
      </a>
      <a       *ngIf="userModuleOptions.includes('products')"
      mat-list-item class="sidenav-item" routerLinkActive="active-link">
        <div class="dh-icon-product dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/box.svg"
            alt="productos de proveedor"
          />
        </div>
        <span class="nav-text">{{ 'PROVIDER.navegationOptions.products' | translate }}</span>
        <span class="selection-bar"></span>
      </a>
      <a
      *ngIf="userModuleOptions.includes('users')"
        mat-list-item
        class="sidenav-item"
        routerLink="/proveedor/usuarios"
        routerLinkActive="active-link"
      >
        <div class="dh-icon-people-group dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/people-group.svg"
            alt="usuarios"
          />
        </div>
        <span class="nav-text">
          {{ 'PROVIDER.navegationOptions.users' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
