import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErroresService } from '../shared/errores.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  public url = environment.rootUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorService: ErroresService
  ) { }

  public async uploadLogo(urlLogo: string) {
    try {
      return this.http
        .post(
          `${this.url}/api-v1/provider/upload/logo`,
          { logo_company: urlLogo },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any ) {
      return throwError(this.errorService.getErrores(error));
    }
  }

  public async ReenviarCodigo( data : any) {
    try {
      return this.http.put(
          `${this.url}/api-v1/provider/update-phone-number`,
          { phone_number: data.phone },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any ) {
      return throwError(this.errorService.getErrores(error));
    }
  }

  public async VerificaMS( VerificaMS: any) {
    try {
      return this.http.put(
          `${this.url}/api-v1/provider/verify-phone-number`,
           VerificaMS,
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any ) {
      return throwError(this.errorService.getErrores(error));
    }
  }

  public async updateLogo(urlLogo: string) {
    try {
      return this.http
        .put(
          `${this.url}/api-v1/provider/update-logo`,
          { logo_company: urlLogo },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any) {
       return throwError(this.errorService.getErrores(error));
    }
  }


  public async getProgressStatus() {
    try {
      return this.http
        .get(`${this.url}/api-v1/provider/progress-status`, {
          headers: this.authService.getToken(),
        })
        .toPromise();
    } catch (error: any) {
       return throwError(this.errorService.getErrores(error));
    }
  }

  public async addConcreteTrucks(trucks: any) {
    try {
      return this.http
        .post(
          `${this.url}/api-v1/provider/construction-vehicles/register`,
          { trucks: trucks },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any) {
       return throwError(this.errorService.getErrores(error));
    }
  }

  public async addServices(concretes: any, bombs: any) {
    try {
      return this.http
        .post(
          `${this.url}/api-v1/provider/add-products-and-services`,
          { concrate: concretes, pumping: bombs },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any) {
       return throwError(this.errorService.getErrores(error));
    }
  }

  public async addCoverageProvider(coverage: any) {
    try {
      return this.http
        .post(
          `${this.url}/api-v1/provider/add-coverage`,
          { coverage: coverage },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any) {
       return throwError(this.errorService.getErrores(error));
    }
  }

  public async addProviderBilling(
    bank_name: string,
    card_number: string,
    interbank_code: string,
    account_name: string,
  ) {
    try {
      return this.http
        .post(
          `${this.url}/api-v1/provider/add-info-biling`,
          {
            bank_name: bank_name,
            card_number: card_number,
            interbank_code: interbank_code,
            account_name,
          },
          {
            headers: this.authService.getToken(),
          },
        )
        .toPromise();
    } catch (error: any) {
      return error;
    }
  }

  public getOrders(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/orders`, {
      headers: this.authService.getToken(),
    });
  }

  public getOrederDetail(id: string): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/order-information/`, {
      headers: this.authService.getToken(),
      params: {
        orderId: id,
      },
    });
  }

  public updateShippingOrder(data: {
    order_id: string;
    status: string;
  }): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/order-shipping-status`,
      data,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateShippingDelivery(data: {
    order_detail_id: string;
    status: string;
  }): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/delivery-shipping-status`,
      data,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public truckOperators() {
    return this.http.get(`${this.url}/api-v1/provider/truck-operators`, {
      headers: this.authService.getToken(),
    });
  }

  public updateTruck(data: {
    order_detail_id: string;
    truck_operator_id: string;
  }): Observable<any> {
    return this.http.put(`${this.url}/api-v1/provider/assign-operator`, data, {
      headers: this.authService.getToken(),
    });
  }

  public changeDeliveryShippingStatus(order_detail_id: string, status: string) {
    return this.http.put(
      `${this.url}/api-v1/provider/delivery-shipping-status`,
      { order_detail_id: order_detail_id, status: status },
      { headers: this.authService.getToken() },
    );
  }

  public getBooking() {
    return this.http.get(`${this.url}/api-v1/provider/booking`, {
      headers: this.authService.getToken(),
    });
  }

  public getProductsServices(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/products-and-services`, {
      headers: this.authService.getToken(),
    });
  }

  public getProvideUserById(idProspect: number): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/users/${idProspect}`, {
      headers: this.authService.getToken(),
    });
  }

  public addPumping(data: any): Observable<any> {
    return this.http.post(`${this.url}/api-v1/provider/add-pumping`, data, {
      headers: this.authService.getToken(),
    });
  }

  public getProviderUsers() {
    return this.http.get(`${this.url}/api-v1/provider/users`, {
      headers: this.authService.getToken(),
    });
  }

  public addConcrate(data: any): Observable<any> {
    return this.http.post(`${this.url}/api-v1/provider/add-concrate`, data, {
      headers: this.authService.getToken(),
    });
  }

  public deleteProviderById(id: number): Observable<any> {
    return this.http.delete(`${this.url}/api-v1/provider/remove-user/${id}`, {
      headers: this.authService.getToken(),
    });
  }

  public updateService(data: any): Observable<any> {
    return this.http.put(`${this.url}/api-v1/provider/update-service`, data, {
      headers: this.authService.getToken(),
    });
  }

  public getProviderById(id: number): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/users/${id}`, {
      headers: this.authService.getToken(),
    });
  }
  public updateConcrate(data: any): Observable<any> {
    return this.http.put(`${this.url}/api-v1/provider/update-concrate`, data, {
      headers: this.authService.getToken(),
    });
  }

  public getTypeUsersAndModules(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/type-users-and-modules`, {
      headers: this.authService.getToken(),
    });
  }

  public createProviderUser(provider: any): Observable<any> {
    return this.http.post(
      `${this.url}/api-v1/provider/register-user`,
      provider,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateProviderUser(provider: any): Observable<any> {
    return this.http.put(`${this.url}/api-v1/provider/update-user`, provider, {
      headers: this.authService.getToken(),
    });
  }

  public getTypeOfUsersAndModules(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/type-users-and-modules`, {
      headers: this.authService.getToken(),
    });
  }

  public getProviderProfile(): Observable<any> {
    return this.http.get(`${this.url}/api-v1/provider/profile`, {
      headers: this.authService.getToken(),
    });
  }

  public updateProviderPhoneNumber(phone: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-phone-number`,
      phone,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public verifyProviderPhoneNumber(verifyData: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/verify-phone-number`,
      verifyData,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public changeProviderPassword(passwordData: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-password`,
      passwordData,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateCompanyData(companyData: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-company`,
      companyData,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateCoverageData(coverage: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-coverage`,
      coverage,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateBillingInformation(billingData: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-billing-information`,
      billingData,
      {
        headers: this.authService.getToken(),
      },
    );
  }

  public updateDocumentation(providerDocumentation: any): Observable<any> {
    return this.http.put(
      `${this.url}/api-v1/provider/update-documentation`,
      providerDocumentation,
      {
        headers: this.authService.getToken(),
      },
    );
  }
}
function throwError(arg0: string) {
  throw new Error('Function not implemented.');
}

