<div class="dh-header-app">
  <div class="dh-flex-row-align-center-justific-space-between dh-header-box">
    <div
      class="dh-header-box-l dh-flex-row-align-center-justific-space-between "
    >
      <mat-icon
        (click)="goRouter('')"
        class="dh-header-box-l-logo dh-decoration-curosr-pinter dh-logo-mobile"
        svgIcon="ic-logo"
      ></mat-icon>
    </div>

    <div
      class="dh-header-box-r dh-flex-row-align-center-justific-space-between  dh-header-container"
    >
      <div class="dh-buttons-section">


        <button
          *ngIf="!mobile && !administratorRoute"
          (click)="
            !login
              ? goRouter('servicio/concreto')
              : goRouter('servicio/cotizacion-en-proceso-concreto')
          "
          mat-button
          class="dh-button-header-start dh-color-blue dh-text-botton-poppins dh-button-header-quote dh-button-size"
        >
          {{ 'HOME.concrete' | translate }}
        </button>

        <button
          *ngIf="!mobile && !administratorRoute"
          (click)="
            !login
              ? goRouter('servicio/concreto')
              : goRouter('servicio/cotizacion-en-proceso-concreto')
          "
          mat-button
          class="dh-button-header-start dh-color-blue dh-text-botton-poppins dh-button-header-quote-cement dh-button-size"
        >
          {{ 'HOME.bomb' | translate }}
        </button>
      </div>

      <div
        *ngIf="!mobile"
        class="dh-cp-box dh-flex-row-align-center-justific-space-between dh-decoration-curosr-pinter"
      >
        <div (click)="goMenuMovile()">
          <mat-icon
            *ngIf="mobile"
            class="dh-menu-icon"
            svgIcon="ic-menu"
          ></mat-icon>
        </div>
      </div>

      <div
        *ngIf="!mobile && !toHideProfileSection"
        class="dh-profile dh-flex-row-align-center-justific-space-evenly dh-login-section"
        [ngClass]="{ 'dh-logged-profile': login, '': !login }"
      >
        <img
          *ngIf="!loading"
          src="../../../../assets/icons/user-icon.svg"
          alt="Comprar concreto premezclado"
          width="25px"
          height="25px"
        />
        <div
          [ngClass]="{ 'dh-text-profil': login, 'dh-text-profil-off': !login }"
          class="dh-text-profil dh-flex-column-justify-start"
        >
          <p
            *ngIf="login && !loading"
            class="dh-text-regular-poppins dh-color-grey-649"
          >
            {{ 'HOME.account' | translate }}
          </p>
          <p
            *ngIf="!loading"
            [ngClass]="{ 'dh-decoration-curosr-pinter': !login }"
            class="dh-text-regular-big-poppins dh-color-blue"
            (click)="!login ? goRouter('acceso') : null"
          >
            {{ login ? data.name + ' ' + data.surname : '' }}
          </p>

          <div class="button-container" *ngIf="!login">
            <p class="register" (click)="goRouter('acceso/tipo-de-usuario')">
              <img
                class="dh-img-register"
                src="../../../assets/icons/register-icon.svg"
                alt="Cotiza concreto premezclado"
              />
              {{ 'HOME.register' | translate }}
            </p>
            <!-- <p class="login" (click)="goRouter('acceso/tipo-de-usuario')"> -->
              <p class="login" (click)="goRouter('acceso/iniciar-sesion')"> 
              <img
                class="dh-img-login"
                src="../../../assets/icons/login-icon.svg"
                alt="Compra concreto premezclado"
              />
              {{ 'HOME.login' | translate }}
            </p>
          </div>
        </div>
        <mat-icon
          *ngIf="login && !loading"
          class="dh-icon-blue dh-decoration-curosr-pinter dh-arrow-display-info"
          svgIcon="ic-arrow-bottom"
          [matMenuTriggerFor]="menu"
        ></mat-icon>
      </div>
    </div>
  </div>
  <mat-menu
    class="dh-contain-header-menu dh-profile-menu-header"
    #menu="matMenu"
  >
    <div class="dh-icon-section">
      <mat-icon
        class="dh-icon-blue dh-decoration-curosr-pinter dh-icon-in-menu"
        svgIcon="ic-arrow-bottom"
      ></mat-icon>
    </div>
    <button
      (click)="goRouter('perfil/')"
      mat-menu-item
      class="dh-button-header-mat-menu-item"
    >
      <mat-icon
        class="dh-icon-blue dh-text-regular-big-poppins dh-color-black-323"
        svgIcon="ic-profile"
      ></mat-icon>
      <span class="dh-text-menu">{{ 'HOME.profile' | translate }}</span>
    </button>
    <button
      (click)="goRouter('servicio/cotizaciones')"
      mat-menu-item
      class="dh-button-header-mat-menu-item"
    >
      <mat-icon class="dh-icon-orders">dehaze</mat-icon>
      <span class="dh-text-menu">{{ 'HOME.quote' | translate }}</span>
    </button>
    <button
      (click)="goRouter('servicio/pedidos')"
      mat-menu-item
      class="dh-button-header-mat-menu-item"
    >
      <img
        src="../../../../assets/icons/order-icon-blue.svg"
        class="dh-menu-icon dh-orders-icon"
        width="19px"
        height="19px"
        alt="Venta de concretro premezclado"
      />
      <span class="dh-text-menu">{{ 'HOME.myOrders' | translate }}</span>
    </button>

    <!-- <button
    (click)="goRouter('operador/proyectos')"
    mat-menu-item
    class="dh-button-header-mat-menu-item"
  >
    <img
      src="../../../../assets/icons/side-nav/provider/close-book-active.svg"
      class="dh-menu-icon dh-orders-icon"
      width="19px"
      height="19px"
      alt="Venta de concretro premezclado"
    />
    <span class="dh-text-menu">{{ 'Bitacora' | translate }}</span>
  </button> -->
    
    <hr class="dh-line-profile-menu" />
    <button
      (click)="goToSignOff()"
      mat-menu-item
      class="dh-button-header-mat-menu-item dh-logout"
    >
      <img
        src="../../../../assets/icons/logout-icon-blue.svg"
        class="dh-menu-icon dh-logout-icon"
        width="21px"
        height="21px"
        alt="Cotizar concretro premezclado"
      />
      <span class="dh-text-menu">{{ 'HOME.signOff' | translate }}</span>
    </button>
  </mat-menu>
</div>

<div *ngIf="mobile" class="dh-menu-mobile">
  <div class="dh-menu-mobile-row">
    <button mat-button class="dh-menu-mobile-element" (click)="goRouter('')">
      <a
        [routerLink]="['']"
        title=""
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="dh-icon-home">
          <img
            width="21px"
            height="21px"
            class="home"
            src="../../../assets/icons/menu-mobile/home-icon-mobile.svg"
            alt="Cotizar concreto premezclado"
          />
        </div>
      </a>
    </button>
    <button
      mat-button
      class="dh-menu-mobile-element"
      (click)="
        !login
          ? goRouter('servicio/concreto')
          : goRouter('servicio/cotizacion-en-proceso-concreto')
      "
    >
      <a
        [routerLink]="
          !login
            ? ['/servicio/concreto']
            : ['/servicio/cotizacion-en-proceso-concreto']
        "
        title=""
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="dh-icon-quotes">
          <img
            width="25px"
            height="21px"
            src="../../../assets/icons/quote-icon.svg"
            alt="Comprar concreto premezclado"
          />
        </div>
      </a>
    </button>

    <button
    *ngIf="login && currentDeliveryId"
    mat-button
    class="dh-menu-mobile-element"
    (click)="goRouter( '/servicio/entregas/' + currentDeliveryId )"
  >
    <a
      title=""
      routerLinkActive="active-link"
      [routerLink]="'/servicio/entregas/' + currentDeliveryId  "
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="dh-icon-truck">
        <img
          width="25px"
          height="21px"
          src="../../../assets/icons/menu-mobile/group-mobile.svg"
          alt="Comprar concreto premezclado"
        />
      </div>
    </a>
  </button>

    <button
      mat-button
      class="dh-menu-mobile-element"
      (click)="
        !login
          ? goRouter('/servicio/cemento')
          : goRouter('/servicio/cotizaciones')
      "
    >
      <a
        [routerLink]="
          !login ? ['/servicio/cemento'] : ['/servicio/cotizaciones']
        "
        title=""
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="dh-icon-order">
          <img
            width="21px"
            height="21px"
            src="../../../assets/icons/menu-mobile/order-icon-gray.svg"
            alt="Comprar concreto premezclado"
          />
        </div>
      </a>
    </button>
    <button      *ngIf="verifyModuleOption('binnacle')" type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/operador/proyectos" title="" routerLinkActive="active-link">
        <div class="dh-icon-menu dh-icon-mobile">
          <img
            width="21px"
            height="21px"
            src="./assets/icons/side-nav/provider/close-book.svg"
            alt="munú de proveedor"
          />
        </div>
      </a>
    </button>
    <button
      mat-button
      class="dh-menu-mobile-element"
      (click)="!login ? goRouter('/perfil/visitante') : goRouter('/perfil')"
    >
      <a
        [routerLink]="!login ? ['/perfil/visitante'] : ['/perfil']"
        title=""
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="dh-icon-profile">
          <img
            width="21px"
            height="21px"
            src="../../../assets/icons/menu-mobile/construction-profile-mobile.svg"
            alt="Cotizar concreto premezclado"
          />
        </div>
      </a>
    </button>

    <button type="button" mat-button class="dh-menu-mobile-element" *ngIf="login">
      <a (click)="logout()">
        <div class="dh-icon-profile dh-icon-mobile">
          <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g >
              <path id="Vector" d="M13.333 11.3333L6.54134 11.3333L8.70801 13.5L7.49967 14.6666L3.33301 10.5L7.49967 6.33329L8.70801 7.49996L6.54134 9.66662L13.333 9.66662L13.333 11.3333ZM16.6663 15.5C16.6663 15.9583 16.503 16.3508 16.1763 16.6775C15.8497 17.0041 15.4575 17.1672 14.9997 17.1666L12.4997 17.1666L12.4997 15.5L14.9997 15.5L14.9997 5.49996L12.4997 5.49996L12.4997 3.83329L14.9997 3.83329C15.458 3.83329 15.8505 3.99662 16.1772 4.32329C16.5038 4.64996 16.6669 5.04218 16.6663 5.49996L16.6663 15.5Z" fill="#c4c4c4"/>
            </g>
          </svg>
          
        </div>
      </a>
    </button>

    
  </div>
</div>
