import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { PARTNER_PHONE_MASK } from 'src/app/shared/input-mask.directive';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent implements OnInit {
  public dataForm: any;
  public phoneMask = PARTNER_PHONE_MASK;

  constructor(
    public dialogCole: MatDialogRef<DataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.dataForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      secondSurname: new UntypedFormControl(''),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
    });
    this.dataForm.controls.name.setValue(this.data.user.data.name);
    this.dataForm.controls.surname.setValue(this.data.user.data.surname);
    this.dataForm.controls.secondSurname.setValue(
      this.data.user.data.second_surname,
    );
    this.dataForm.controls.phone.setValue(this.data.user.data.phone);
  }

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }

  public async upgradeUser(): Promise<any> {
    const body = {
      name: this.dataForm.controls.name.value,
      surname: this.dataForm.controls.surname.value,
      second_surname: this.dataForm.controls.secondSurname.value,
      phone: this.dataForm.controls.phone.value,
    };
    await this.userService.upgradeUser(body).then((ress) => {
      if (ress) {
        this.closeModal(true);
      }
    });
  }
}
