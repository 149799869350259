import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { isPlatformServer } from '@angular/common';
import { href } from 'src/environments/environment';

@Component({
  selector: 'app-video-banner',
  templateUrl: './video-banner.component.html',
  styleUrls: ['./video-banner.component.scss'],
})
export class VideoBannerComponent {
  private isServer: boolean;
  public options: AnimationOptions;
  public hrefAux = href;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isServer = isPlatformServer(this.platformId);
    this.options = {
      path: this.isServer
        ? this.hrefAux + 'assets/video-banner/video-banner.json'
        : 'assets/video-banner/video-banner.json',
    };
  }
}
