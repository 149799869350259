<div class="dh-sign-off dh-flex-column-align-center">
  <div class="dh-dialog-header-icon-div dh-flex-row-align-center-justific-end">
    <mat-icon
      class="dh-dialog-sign-off dh-decoration-curosr-pinter"
      svgIcon="ic-close"
      (click)="closeModal(false)"
    ></mat-icon>
  </div>
  <mat-icon class="dh-dialog-icon-alert" svgIcon="ic-alert"></mat-icon>
  <p class="dh-text-little-titule-poppins dh-color-grey-363">
    {{ '¿Seguro que deseas cerrar sesión?' | translate }}
  </p>
  <div class="dh-sign-off-line"></div>
  <div class="dh-sign-off-button">
    <button
      class="dh-cancel"
      (click)="closeModal(false)"
      mat-stroked-button
      color="primary"
    >
      {{ 'HOME.SINGOFF.cancel' | translate }}
    </button>
    <button
      class="dh-signoff"
      (click)="closeModal(true)"
      mat-flat-button
      color="primary"
    >
      {{ 'HOME.SINGOFF.signOff' | translate }}
    </button>
  </div>
</div>
