import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-go-login',
  templateUrl: './go-login.component.html',
  styleUrls: ['./go-login.component.scss'],
})
export class GoLoginComponent {
  constructor(
    public dialogCole: MatDialogRef<GoLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public closeModal(value: boolean): void {
    this.dialogCole.close(value);
  }
}
