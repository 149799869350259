import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEFAULT_LANGUAGE } from './shared/data';
import { SubSink } from 'subsink';
import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { Router } from '@angular/router';
import { BambuService } from './services/bambu.service';
import { href } from 'src/environments/environment';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Dochmart cotiza y compra concreto premezclado.';
  auth = false;
  footer = false;
  hrefAux = href;
  isProduction = environment.production;
  private subs = new SubSink();
  public loading = true;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private router: Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
    private metaTagService: Meta,
    private titleService: Title,
    private bambuService: BambuService,
    public authService: AuthService,
    @Inject(DOCUMENT) private dom: Document,
  ) {
    this.metaTagService.addTags([
      {
        name: 'description',
        content:
          'Conoce dochmart el sitio web para la compra de concreto premezclado en Mexico. Vive la nueva experiencia en el mundo de la construcción.',
      },
      {
        name: 'keywords',
        content:
          'Concreto premezclado precio, olla revolvedora, bombeo de concreto premezclado, Cemento, Cruz Azul, Holcim, Apasco, Fortaleza, Concreto Maya, Cemex, Cementos y Concretos Nacionales, CYCNA, Moctezuma, Lafarge, Cementos de Chihuahua, construcción residencial, concreto para cimientos, concreto para losas, concreto estructural, donde comprar cemento por olla, precio del concreto premezclado, metro cúbico de concreto premezclado.',
      },
      {
        name: 'robots',
        content: this.isProduction ? 'index, follow' : 'noindex, nofollow',
      },
      { name: 'author', content: 'Bambu Mobile' },
      { charset: 'UTF-8' },
      { name: 'og:locale', content: 'es_MX' },
      { name: 'og:site_name', content: 'dochmart' },
      { name: 'og:type', content: 'website' },
      {
        name: 'og:image',
        content:
          'https://app-dochmart-api-prod.herokuapp.com/static/dochmart.jpg',
      },
    ]);

    this.titleService.setTitle('Dochmart - Home');
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(DEFAULT_LANGUAGE);

    //cambiar dominio en productivo
    const domain = isPlatformServer(platformId) ? this.hrefAux : '';

    this.iconRegistry
      .addSvgIcon(
        'ic-logo',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/LogoAzul.svg',
        ),
      )
      .addSvgIcon(
        'ic-cp',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/cp.svg',
        ),
      )
      .addSvgIcon(
        'ic-profile',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/profile.svg',
        ),
      )
      .addSvgIcon(
        'ic-arrow-bottom',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/arrow-bottom.svg',
        ),
      )
      .addSvgIcon(
        'ic-menu',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/Menu.svg',
        ),
      )
      .addSvgIcon(
        'ic-arrow',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/Vector.svg',
        ),
      )
      .addSvgIcon(
        'ic-download',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/download-files.svg',
        ),
      )
      .addSvgIcon(
        'ic-arrow-before',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/arrow-before.svg',
        ),
      )
      .addSvgIcon(
        'ic-text',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/text-icon.svg',
        ),
      )
      .addSvgIcon(
        'ic-text-list-number',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/text-list-number-icon.svg',
        ),
      )
      .addSvgIcon(
        'ic-clip',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/clip-icon.svg',
        ),
      )
      .addSvgIcon(
        'ic-camera',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/camera-icon.svg',
        ),
      )
      .addSvgIcon(
        'ic-filter',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/filter.svg',
        ),
      )
      .addSvgIcon(
        'ic-note',
        sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/note.svg',
        ),
      )
      .addSvgIcon(
        'ic-facebook',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/facebook.svg',
        ),
      )
      .addSvgIcon(
        'ic-in',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/in.svg',
        ),
      )
      .addSvgIcon(
        'ic-twitter',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/twitter.svg',
        ),
      )
      .addSvgIcon(
        'ic-instagram',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/instagram.svg',
        ),
      )
      .addSvgIcon(
        'ic-logo-dochmart',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/Logo-dochmart.svg',
        ),
      )
      .addSvgIcon(
        'ic-arrow-right',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/arrow-right.svg',
        ),
      )
      .addSvgIcon(
        'ic-close',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/close.svg',
        ),
      )
      .addSvgIcon(
        'ic-eye',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/eye.svg',
        ),
      )
      .addSvgIcon(
        'ic-eye-off',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/eye-off.svg',
        ),
      )
      .addSvgIcon(
        'ic-arrow-l',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/arrow-l.svg',
        ),
      )
      .addSvgIcon(
        'ic-check',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/check.svg',
        ),
      )
      .addSvgIcon(
        'ic-info',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/info.svg',
        ),
      )
      .addSvgIcon(
        'ic-alert',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/icon_alert.svg',
        ),
      )
      .addSvgIcon(
        'ic-edit',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/edit.svg',
        ),
      )
      .addSvgIcon(
        'ic-question',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/question.svg',
        ),
      )
      .addSvgIcon(
        'ic-add',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/add.svg',
        ),
      )
      .addSvgIcon(
        'ic-send-mail',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/Send_mail.svg',
        ),
      )
      .addSvgIcon(
        'ic-doch',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          domain + 'assets/icons/doch.svg',
        ),
      );
    this.subs.add(
      this.router.events.subscribe(() => {
        this.auth = this.location.path().includes('acceso');
        this.footer =
          this.location.path().includes('acceso') ||
          this.location.path().includes('email-confirmado') ||
          this.location.path().includes('resumen-de-cotizacion-enviado') ||
          this.location.path().includes('recuperar-contrasena')
            ? false
            : true;
      }),
    );
  }
  ngOnInit(): void {
    this.bambuService.setCanonicalURL();
    this.authService.checkLogin();

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
}
